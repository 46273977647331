import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useAccount } from '../components/Account';
import { createAxiosInstance } from './axios';

declare const window: any;

const SERVER_API_URI = process.env.NODE_ENV === 'production' ? window.env.SERVER_API_URI
  : process.env.REACT_APP_SERVER_API_URI || "http://127.0.0.1:8080/api"

export const useApi = () => {

  const { session } = useAccount()

  const headers = (token: string) => {
    return { 'Authorization': `Bearer ${token}`, }
  }

  const config = (data?: any) => {
    if (data) {
      return { data: data }
    }
  }

  const toServerPath = (path: string) => {
    return `${SERVER_API_URI}/${path}`
  }

  const params = (start: number, limit: number, search?: string) => {
    let ret = ""
    if (start || limit || search) {
      ret = "?"
    }
    if (start !== undefined) {
      ret += `start=${start}`
    }
    if (limit !== undefined) {
      if (start !== undefined) {
        ret += "&"
      }
      ret += `limit=${limit}`
    }
    if (search !== undefined) {
      if (start !== undefined || limit !== undefined) {
        ret += "&"
      }
      ret += `search=${search}`
    }
    return ret
  }

  const fetchItem = useCallback(async (
    path: string,
    callback?: (a: any) => void
  ): Promise<any> => {
    try {
      const axiosInstance = createAxiosInstance(session)
      const response = await axiosInstance.get(toServerPath(path));
      if (callback) {
        callback(response.data); // Call the callback with the fetched data
      }
      return response.data; // Return the fetched data
    } catch (error) {
      console.error('Error fetching item:', error);
      throw error; // Optionally rethrow the error to handle it upstream
    }
  }, [session])

  const fetchData = useCallback(async (
    path: string,
    callback?: (data: any[]) => void
  ): Promise<any> => {
    try {
      const axiosInstance = createAxiosInstance(session);
      const response = await axiosInstance.get(toServerPath(path)); // Use the Axios instance
      if (callback) {
        callback(response.data); // Call the callback with the fetched data
      }
      return response.data; // Return the fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Optionally rethrow the error to handle it upstream
    }
  }, [session])

  const postData = useCallback(async (
    path: string,
    data: any,
    callback?: () => void
  ): Promise<void> => {
    const axiosInstance = createAxiosInstance(session);
    try {
      await axiosInstance.post(toServerPath(path), data);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  }, [session])

  const putData = useCallback(async (
    path: string,
    data: any,
    callback?: () => void
  ): Promise<void> => {
    const axiosInstance = createAxiosInstance(session);
    try {
      await axiosInstance.put(toServerPath(path), data);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error putting data:', error);
      throw error;
    }
  }, [session])

  const patchData = useCallback(async (
    path: string,
    data: any,
    callback?: () => void
  ): Promise<void> => {
    const axiosInstance = createAxiosInstance(session);
    try {
      // Make the PATCH request
      await axiosInstance.patch(toServerPath(path), data);
      // Invoke the callback if provided
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error patching data:', error);
      throw error; // Rethrow the error so it can be handled by the caller
    }
  }, [session])

  const deleteData = useCallback(async (
    path: string,
    callback?: () => void,
    data?: any
  ): Promise<void> => {
    const axiosInstance = createAxiosInstance(session);
    try {
      await axiosInstance.delete(toServerPath(path), config(data));
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      throw error;
    }
  }, [session])

  const downloadFile = useCallback(async (
    path: string,
    filename: string,
    filetype: string,
  ) => {
    const axiosConfig: AxiosRequestConfig = {
      responseType: "blob",
    }
    const axiosInstance = createAxiosInstance(session);
    axiosInstance.get(toServerPath(path), axiosConfig)
      .then(
        (response) => {
          const fileURL = window.URL.createObjectURL(new File([new Blob([response.data])], filename, { type: filetype }))
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = filename
          alink.click();
        },
        (error) => {
          console.error(error)
        }
      )
  }, [session])

  return {
    headers,
    config,
    toServerPath,
    params,
    fetchItem,
    fetchData,
    postData,
    putData,
    patchData,
    deleteData,
    downloadFile
  }
}
