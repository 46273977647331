import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "./Account"
import { AuthForm } from './form'
import React from 'react'
import { EmailInput, PasswordInput } from "./form/input";
import { Box, Center, Text } from "@chakra-ui/react";
import { LoginButton } from "./button";

interface LoginInfo {
  email: string;
  password: string;
}

export const Login = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState<string | null>(null)
  const { session, getSession, authenticate } = useContext(AccountContext)

  useEffect(() => {
    if (!session?.isValid()) {
      getSession()
      return
    } else {
      return navigate("/home", { replace: true });
    }
  }, [session, getSession, navigate])

  const onSuccess = () => {
    navigate("/home", { replace: true });
  }

  const onError = (err: Error) => {
    setError(err.name === "NotAuthorizedException" ? "Incorrect username or password." : "Error authenticating account")
  }

  const onNewPasswordRequired = () => {
    navigate("/reset-password", { replace: true });
  }

  const onSubmit = (values: LoginInfo) => {
    authenticate(values.email, values.password, onSuccess, onError, onNewPasswordRequired);
  }

  return (
    <AuthForm
      name="login"
      title="Login"
      buttonText="Login"
      error={error}
      onSubmit={onSubmit}
      initialValues={{ email: "", password: "" }}
    >
      {(location.state && location.state.msg) &&
        <>
          <Center pt={5}>
            <Text w="70%" textAlign="center">{location.state.msg}</Text>
          </Center>
          <Box pt={5} />
        </>
      }
      <EmailInput testId="email" />
      <PasswordInput testId="password" />
      <Box pt={4} />
      <LoginButton testId="log-in-button" />
    </AuthForm>
  )
}
