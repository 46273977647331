import { Box, Center, Text, HStack, Heading, VStack } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { PropsWithChildren } from "react";
import { BORDER_PRIMARY_DARK, COLOUR_PRIMARY_LIGHT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../theme";

export interface AuthFormProps extends PropsWithChildren {
  name: string
  title: string
  buttonText: string
  initialValues?: object
  onSubmit: (a: any, cb: () => void) => void,
  error: string | null
}

export const AuthForm = (props: AuthFormProps) => {

  return (
    <>
      <Center
        w='calc(100vw)'
        h='calc(100vh)'
        test-id="login-form"
        background={COLOUR_PRIMARY_LIGHT}
      >
        <Box
          w='22%'
          h='auto'
          borderRadius={RADIUS_PRIMARY}
          border={BORDER_PRIMARY_DARK}
          background={COLOUR_PRIMARY_WHITE}
          padding={5}
        >
          <Heading w={40} size="lg">Rubric Analytica</Heading>

          <Box pt={5} />
          <Box pt={10} />
          <Center>
            <Text fontSize="lg">{props.title}</Text>
          </Center>
          <Box pt={10} />

          <Formik
            initialValues={props.initialValues || {}}
            validateOnMount
            enableReinitialize
            onSubmit={(values, actions) => {
              setTimeout(() => {
                props.onSubmit(values, () => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                });
              }, 1000);
            }}
          >
            {(formik) => {
              return <Form>
                <HStack spacing="100px" align="stretch">
                  <VStack w="full">
                    {props.children}
                  </VStack>
                </HStack>
                {
                  props.error &&
                  <Center>
                    <Text textColor="red" > {props.error} </Text>
                  </Center>
                }
              </Form>
            }}
          </Formik>
        </Box >
      </Center>
    </>
  )
}
