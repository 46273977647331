import { IconButton, Input, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { Result, Student } from "../../../../data/classes";
import { fullname } from "../../../../data/functions";
import { useRequests } from "../../../../data/requests";
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, COLOUR_SECONDARY_LIGHT, ICON_CROSS, ICON_RESULT, ICON_TICK } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { TestTableTabProps } from "../test-profile";

const R = require('ramda')

export const TestResults: React.FC<TestTableTabProps> = ({ ...props }) => {

  const { fetchAllResultsByTestId, fetchAllStudentsByClassId, upsertResult } = useRequests()

  const fetchAndMergeData = async (setItems: Function) => {
    try {
      // Fetch data asynchronously
      const students = await fetchAllStudentsByClassId(props.test.classId);
      const results = await fetchAllResultsByTestId(props.test.id);

      // Merge data
      // TODO: Error here if students.map is not a function
      const items: object[] = students.map((student: Student) => {
        const result = results.find((result: Result) => result.studentId === student.id);
        let item = new Result(
          student.orgId,
          props.test.classId,
          props.test.id,
          student.id,
          student.identifier,
          result?.completed || false,
          result?.score || 0,
          result?.timeRemaining || 0,
          result?.active || true,
          fullname(student),
          student.level,
          student.id
        )
        return item
      });
      // Update state or items
      setItems(items);
    } catch (error) {
      console.error('Error fetching or merging data:', error);
      setItems([]); // Optionally set to an empty array on error
    }
  }

  const toggleCompleted = (
    e: React.MouseEvent,
    item: any,
    items: any[],
    setItems: (items: any[]) => void
  ) => {
    // This should be used to stop the row click event occuring too.
    // Altough I have also turned off row clicking for this table.
    e.stopPropagation()
    item.completed = !item.completed
    updateData(item, items, setItems)
  }

  const onChangeScore = (
    e: React.FormEvent<HTMLInputElement>,
    item: any,
    items: any[],
    setItems: (items: any[]) => void
  ) => {
    item.score = parseFloat(e.currentTarget.value) / 25
    updateData(item, items, setItems)
  }

  const onChangeTimeRemaining = (
    e: React.FormEvent<HTMLInputElement>,
    item: any,
    items: any[],
    setItems: (items: any[]) => void
  ) => {
    item.timeRemaining = parseFloat(e.currentTarget.value)
    updateData(item, items, setItems)
  }

  const updateData = (
    item: Result,
    items: Result[],
    setItems: (items: Result[]) => void
  ) => {
    let currentItemUpdated: Result | undefined
    const updatedItems = R.map((r: Result) => {
      if (r.id === item.id) {
        currentItemUpdated = item
        return item
      }
      return r
    }, items)
    setItems(updatedItems)
    if (currentItemUpdated === undefined) {
      throw new Error('could not find item to update')
    }
    // Because this information is just in here for convenienve,
    // and it is stored in the Student data anyways.
    //
    // Also, this clone is needed to prevent these fields dissapearing
    // from the table row.
    let clone = { ...currentItemUpdated }
    delete clone.name
    delete clone.level
    // // upsert to db
    upsertResult(clone, item.testId, item.studentId, () => { })
  }

  const toggle = (
    column: ReactElement<RTableColumnProps>,
    item: any,
    items: any[],
    setItems: (items: any[]) => void
  ): ReactElement => {
    let value = item.completed
    return <IconButton
      icon={value ? ICON_TICK : ICON_CROSS}
      bg={value ? COLOUR_SECONDARY_LIGHT : COLOUR_PRIMARY_LIGHT}
      test-id="toggle-completed"
      border={BORDER_DEFAULT}
      onClick={(e: React.MouseEvent) => toggleCompleted(e, item, items, setItems)}
      aria-label=""
    />
  }

  const trueOrFalse = (
    column: ReactElement<RTableColumnProps>,
    item: any,
  ): ReactElement => {
    let value = item.completed
    return <Text>{value ? "Yes" : "No"}</Text>
  }

  const displayScore = (
    column: ReactElement<RTableColumnProps>,
    item: any,
  ): ReactElement => {
    let value = item.score * 25
    return <Text>{value}</Text>
  }

  const value = (column: ReactElement<RTableColumnProps>, value: any) => {
    if (value === undefined) {
      return "-"
    }
    if (typeof value === 'boolean') {
      return value
    }
    if (value === "true") {
      return true
    }
    if (value === "false") {
      return false
    }
    if (isNaN(value)) {
      return value
    }
    if (column.props.format === "percentage") {
      return `${Number(value * 100).toFixed(1)}%`
    }
    if (Number.isInteger(value)) {
      return value
    }
    return Number(value).toFixed(2)
  }

  const field = (column: ReactElement<RTableColumnProps>, item: any) => {
    if (column.props.id === undefined) {
      throw new Error("column is missing an id")
    }
    let v = item[column.props.id]
    return value(column, v)
  }

  const editableScore = (
    column: ReactElement<RTableColumnProps>,
    item: any,
    items: any[],
    setItems: () => {}
  ): ReactElement => {
    let v = field(column, item)
    if (!item.completed) {
      return <>-</>
    }
    return (
      <Input
        defaultValue={v}
        onChange={
          (e) => { onChangeScore(e, item, items, setItems) }
        }
      />
    )
  }

  const editableTimeRemaining = (
    column: ReactElement<RTableColumnProps>,
    item: any,
    items: any[],
    setItems: () => {}
  ): ReactElement => {
    let v = field(column, item)
    if (!item.completed) {
      return <>-</>
    }
    return (
      <Input
        defaultValue={v}
        onChange={
          (e) => { onChangeTimeRemaining(e, item, items, setItems) }
        }
      />
    )
  }

  // TODO: Possibly also add an undo button.
  return (
    <>
      <AnalyticaTable
        icon={ICON_RESULT}
        fetchItems={fetchAndMergeData}
        hidePagination
        scrollable
        editable
      >
        <RTableActions name='RTableActions'> </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn id="name" name="Name" />
          <RTableColumn id="identifier" name="Identifier" />
          <RTableColumn id="level" name="Level" />
          <RTableColumn id="completed"
            name={!props.test.completed ? "Completed (Click to Toggle)" : "Completed"}
            component={!props.test.completed ? toggle : trueOrFalse}
          />
          <RTableColumn id="score" name="Score (Out of 25)"
            component={!props.test.completed ? editableScore : displayScore}
          />
          <RTableColumn id="timeRemaining" name="Time Remaining (s)"
            component={!props.test.completed ? editableTimeRemaining : undefined}
          />
        </RTableColumns>
      </AnalyticaTable>
    </>
  )
}
