import { Box } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { AnalyticaTable } from '../../table';
import { ICON_ORG } from '../../../theme';
import { AddButton } from '../../button';
import { RTableColumn, RTableColumns } from '../../table/column';
import { RTableActions } from '../../table/action';
import { OrgModal } from '../../modal/org';
import { AccountContext } from '../../Account';
import { ModalContext } from '../../Modal';
import { useRequests } from '../../../data/requests';

export const Orgs = () => {

  const { setPageTitle } = useContext(AccountContext)
  const { setModalContent } = useContext(ModalContext)
  const { fetchOrgs, fetchOrgsWithSearch } = useRequests()

  useEffect(() => {
    setPageTitle("Organisations")
  }, [setPageTitle]);

  return (
    <Box
      paddingX={5}
    >
      <AnalyticaTable
        icon={ICON_ORG}
        fetchItems={fetchOrgs}
        fetchItemsWithSearch={fetchOrgsWithSearch}
        searchable
        searchOnChange
        fullscreen
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="New Organisation"
            onClick={() => setModalContent(
              <OrgModal mode="add" />
            )}
            testId="new-org-button"
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn id="name" name="Name" />
          <RTableColumn id="numClasses" name="#Classes" />
          <RTableColumn id="numStudents" name="#Students" />
          <RTableColumn id="primaryAddress" name="Primary Address" />
        </RTableColumns>
      </AnalyticaTable>
    </Box>
  )
}
