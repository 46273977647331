import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import React from 'react';
import { AccountContext } from '../../Account';
import { Loading } from '../../loading';
import { AnalyticaTable } from '../../table';
import { Org, StaffMember, Student } from '../../../data/classes';
import { Box } from '@chakra-ui/react';
import { ICON_ADD, ICON_STUDENT } from '../../../theme';
import { AddButton } from '../../button';
import { fullname } from '../../../data/functions';
import { RTableActions } from '../../table/action';
import { RTableColumn, RTableColumns } from '../../table/column';
import { StudentModal } from '../../modal/student';
import { PageController } from '../../controller/page-controller';
import { ModalContext } from '../../Modal';
import { RTableColumnProps } from '../../table/column/r-table-column';
import { useRequests } from '../../../data/requests';

export const Students = () => {

  const { getCognitoId, isStaff, setPageTitle } = useContext(AccountContext)
  const cognitoId = getCognitoId()
  const { setModalContent } = useContext(ModalContext)
  const { fetchOrgById, fetchStaffMemberByCognitoId, fetchStudentsByStaffMemberId, fetchStudentsByStaffMemberIdWithSearch } = useRequests()
  const [staffMember, setStaffMember] = useState<StaffMember>()
  const [org, setOrg] = useState<Org>()

  const load = useCallback(() => {
    if (!staffMember) {
      fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
    }
    if (staffMember) {
      fetchOrgById(staffMember.orgId, setOrg)
    }
  }, [staffMember, cognitoId]);

  useEffect(() => {
    setPageTitle("Students")
    load()
  }, [setPageTitle, load])

  const userIsStaff = isStaff()
  if (!userIsStaff || !org || !staffMember) {
    return <Loading />
  }

  const fetchStudentsWithSearch = (search: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByStaffMemberIdWithSearch(staffMember.id, search, setItems, start, limit);
  }

  const fetchStudents = (setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByStaffMemberId(staffMember.id, setItems, start, limit);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: Student) => {
    return fullname(s)
  }

  return (
    <Box
      paddingX={5}
    >
      <PageController>
        <AnalyticaTable
          icon={ICON_STUDENT}
          fetchItems={fetchStudents}
          fetchItemsWithSearch={fetchStudentsWithSearch}
          searchable
          searchOnChange
          fullscreen
        >
          <RTableActions name='RTableActions'>
            <AddButton
              title="New Student"
              leftIcon={ICON_ADD}
              onClick={() => setModalContent(
                <StudentModal mode='add' org={org} />
              )}
              testId="new-student-button"
            />
          </RTableActions>

          <RTableColumns name='RTableColumns'>
            <RTableColumn name="Name" component={fullnameComponent} />
            <RTableColumn id="level" name="Level" />
            <RTableColumn id="yearLevel" name="yearLevel" />
            <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
            <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
          </RTableColumns>
        </AnalyticaTable>

      </PageController>
    </Box>
  )
}

