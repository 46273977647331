import { useCallback } from 'react';
import { useApi } from './api';
import { Admin, Class, Org, Result, StaffMember, Student, Test } from './classes';

export const useRequests = () => {

  const {
    params,
    fetchData,
    fetchItem,
    postData,
    putData,
    patchData,
    deleteData,
    downloadFile,
  } = useApi()

  // Get Item

  const fetchStudentById = useCallback(async (studentId: string, setState: (s: Student) => void) => {
    fetchItem(`v1/student/${studentId}`, setState)
  }, [fetchItem])

  const fetchTestById = useCallback(async (testId: string, setState: (t: Test) => void) => {
    fetchItem(`v1/test/${testId}`, setState)
  }, [fetchItem])

  const fetchResultById = useCallback(async (resultId: string, setState: (r: Result) => void) => {
    fetchItem(`v1/result/${resultId}`, setState)
  }, [fetchItem])

  const fetchAdminById = useCallback(async (adminId: string, setState: (a: Admin) => void) => {
    fetchItem(`v1/admin/${adminId}`, setState)
  }, [fetchItem])

  const fetchAdminByCognitoId = useCallback(async (cognitoId: string, setState: (a: Admin) => void) => {
    fetchItem(`v1/admin/cognito/${cognitoId}`, setState)
  }, [fetchItem])

  const fetchStaffMemberByCognitoId = useCallback(async (cognitoId: string, setState: (s: StaffMember) => void) => {
    fetchItem(`v1/staff/cognito/${cognitoId}`, setState)
  }, [fetchItem])

  const fetchStaffMemberById = useCallback(async (staffId: string, setState: (s: StaffMember) => void) => {
    fetchItem(`v1/staff/${staffId}`, setState)
  }, [fetchItem])

  const fetchOrgById = useCallback(async (orgId: string, setState: (o: Org) => void) => {
    fetchItem(`v1/org/${orgId}`, setState)
  }, [fetchItem])

  const fetchClassById = useCallback(async (classId: string, setState: (c: Class) => void) => {
    fetchItem(`v1/class/${classId}`, setState)
  }, [fetchItem])

  // Get Data

  const fetchOrgs = useCallback(async (setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/all${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchOrgsWithSearch = useCallback(async (search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/all${params(start, limit)}&search=${search}`, setState)
  }, [fetchData, params])

  const fetchClassesByOrgId = useCallback(async (orgId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/${orgId}/classes${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchClassesByOrgIdWithSearch = useCallback(async (orgId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/${orgId}/classes${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchClassesByStaffMemberId = useCallback(async (staffMemberId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/staff/${staffMemberId}/classes${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchClassesByStaffMemberIdWithSearch = useCallback(async (staffMemberId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/staff/${staffMemberId}/classes${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchClassesByStudentId = useCallback(async (studentId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/student/${studentId}/classes${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchClassesByStudentIdWithSearch = useCallback(async (studentId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/student/${studentId}/classes${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchAdmins = useCallback(async (setState: (a: any[]) => void) => {
    fetchData(`v1/admin/all`, setState)
  }, [fetchData])

  const fetchAdminsWithSearch = useCallback(async (search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/admin/all${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchStaffByOrgId = useCallback(async (orgId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/${orgId}/staff${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchStaffByOrgIdWithSearch = useCallback(async (orgId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/${orgId}/staff${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchStaffByClassId = useCallback(async (classId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/class/${classId}/staff${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchStaffByClassIdWithSearch = useCallback(async (classId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/class/${classId}/staff${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchStaffByTestId = useCallback(async (testId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/test/${testId}/staff${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchStaffByTestIdWithSearch = useCallback(async (testId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/test/${testId}/staff${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchStudentsByOrgId = useCallback(async (orgId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/${orgId}/students${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchStudentsByOrgIdWithSearch = useCallback(async (orgId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/org/${orgId}/students${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchStudentsByClassId = useCallback(async (classId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/class/${classId}/students${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchStudentsByClassIdWithSearch = useCallback(async (classId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    console.log(start, limit, search)
    fetchData(`v1/class/${classId}/students${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchStudentsByStaffMemberId = useCallback(async (staffMemberId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/staff/${staffMemberId}/students${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchStudentsByStaffMemberIdWithSearch = useCallback(async (staffMemberId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/staff/${staffMemberId}/students${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchAllStudentsByClassId = useCallback(async (classId: string): Promise<Student[]> => {
    try {
      return fetchData(`v1/class/${classId}/students${params(0, -1)}`)
    } catch (error) {
      console.error('Failed to fetch students:', error);
      return [];
    }
  }, [fetchData, params])

  const fetchAllResultsByTestId = useCallback(async (testId: string): Promise<Result[]> => {
    try {
      return fetchData(`v1/test/${testId}/results${params(0, -1)}`)
    } catch (error) {
      console.error('Failed to fetch results:', error);
      return [];
    }
  }, [fetchData, params])

  const fetchTests = useCallback(async (setState: (a: any[]) => void) => {
    fetchData(`v1/tests`, setState)
  }, [fetchData])

  const fetchTestsByClassId = useCallback(async (classId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/class/${classId}/tests${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchTestsByClassIdWithSearch = useCallback(async (classId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/class/${classId}/tests${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchTestsByStudentId = useCallback(async (studentId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/student/${studentId}/tests${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchTestsByStudentIdWithSearch = useCallback(async (studentId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/student/${studentId}/tests${params(start, limit, search)}`, setState)
  }, [fetchData, params])

  const fetchTestsByStaffMemberId = useCallback(async (staffMemberId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/staff/${staffMemberId}/tests${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchResults = useCallback(async (setState: (a: any[]) => void) => {
    fetchData(`v1/results`, setState)
  }, [fetchData])

  const fetchResultsByTestId = useCallback(async (testId: string, setState: (a: any[]) => void, start: number, limit: number) => {
    fetchData(`v1/tests/${testId}/results${params(start, limit)}`, setState)
  }, [fetchData, params])

  const fetchResultsByStudentId = useCallback(async (studentId: string, setState: (a: any[]) => void) => {
    fetchData(`v1/student/${studentId}/results`, setState)
  }, [fetchData])

  const fetchDashboardAggregations = useCallback(async (setState: (a: any) => void) => {
    fetchData(`v1/dashboard`, setState)
  }, [fetchData])

  const fetchClassFeedback = useCallback(async (classId: string, setState: (a: any) => void) => {
    fetchData(`v1/class/${classId}/feedback`, setState)
  }, [fetchData])

  // Post 

  const addOrg = useCallback(async (o: Org, callback: () => void) => {
    postData(`v1/org`, o, callback)
  }, [postData])

  const addClass = useCallback(async (c: Class, callback: () => void) => {
    postData(`v1/class`, c, callback)
  }, [postData])

  const inviteAdmin = useCallback(async (a: Admin, callback: () => void) => {
    postData(`v1/admin/invite`, a, callback)
  }, [postData])

  const inviteStaffMember = useCallback(async (s: StaffMember, callback: () => void) => {
    postData(`v1/staff/invite`, s, callback)
  }, [postData])

  const addStudent = useCallback(async (s: Student, callback: () => void) => {
    try {
      await postData(`v1/student`, s, callback)
    } catch (error) {
      throw error
    }
  }, [postData])

  const upsertResult = useCallback(async (r: Result, testId: string, studentId: string, callback: () => void) => {
    postData(`v1/tests/${testId}/students/${studentId}/results`, r, callback)
  }, [postData])

  const generateTest = useCallback(async (classId: string, callback: () => void) => {
    postData(`v1/class/${classId}/test`, {}, callback)
  }, [postData])

  // Put

  const updateOrg = useCallback(async (o: Org, callback: () => void) => {
    putData(`v1/org/${o.id}`, o, callback)
  }, [putData])

  const updateClass = useCallback(async (c: Class, callback: () => void) => {
    putData(`v1/class/${c.id}`, c, callback)
  }, [putData])

  const updateAdmin = useCallback(async (a: Admin, callback: () => void) => {
    putData(`v1/admin/${a.id}`, a, callback)
  }, [putData])

  const updateStaffMember = useCallback(async (s: StaffMember, callback: () => void) => {
    putData(`v1/staff/${s.id}`, s, callback)
  }, [putData])

  const updateStudent = useCallback(async (s: Student, callback: () => void) => {
    putData(`v1/student/${s.id}`, s, callback)
  }, [putData])

  const updateTest = useCallback(async (t: Test, callback: () => void) => {
    putData(`v1/test/${t.id}`, t, callback)
  }, [putData])

  const completeTest = useCallback(async (t: Test, callback: () => void) => {
    putData(`v1/test/${t.id}/complete`, t, callback)
  }, [putData])

  // Patch

  const patchStudentClasses = useCallback(async (studentId: string, added: string[]) => {
    patchData(`v1/student/${studentId}/classes`, { classIds: added })
  }, [patchData])

  // Delete

  const deleteStudentClasses = useCallback(async (studentId: string, removed: string[]) => {
    deleteData(`v1/student/${studentId}/classes`, undefined, { classIds: removed })
  }, [deleteData])

  const deleteAdmin = useCallback(async (admin: Admin, callback: () => void) => {
    deleteData(`v1/org/${admin.id}`, callback)
  }, [deleteData])

  const deleteOrg = useCallback(async (org: Org, callback: () => void) => {
    deleteData(`v1/org/${org.id}`, callback)
  }, [deleteData])

  const deleteClass = useCallback(async (clazz: Class, callback: () => void) => {
    deleteData(`v1/class/${clazz.id}`, callback)
  }, [deleteData])

  const deleteStaffMember = useCallback(async (staffMember: StaffMember, callback: () => void) => {
    deleteData(`v1/staff/${staffMember.id}`, callback)
  }, [deleteData])

  const deleteStudent = useCallback(async (student: Student, callback: () => void) => {
    deleteData(`v1/student/${student.id}`, callback)
  }, [deleteData])

  const deleteTest = useCallback(async (test: Test, callback: () => void) => {
    deleteData(`v1/test/${test.id}`, callback)
  }, [deleteData])

  // Download

  const downloadPDF = useCallback(async (path: string, filename: string) => {
    downloadFile(path, filename, 'application/pdf')
  }, [downloadFile])

  const downloadCSV = useCallback(async (path: string, filename: string) => {
    downloadFile(path, filename, 'text/csv')
  }, [downloadFile])

  const downloadTestPDF = useCallback(async (testId: string, filename: string) => {
    downloadPDF(`v1/test/${testId}/pdf`, filename)
  }, [downloadPDF])

  const downloadImportTemplateCSV = useCallback(async (filename: string) => {
    downloadCSV('v1/template/import', filename)
  }, [downloadCSV])

  return {
    fetchStudentById,
    fetchTestById,
    fetchResultById,
    fetchAdminById,
    fetchAdminByCognitoId,
    fetchStaffMemberByCognitoId,
    fetchStaffMemberById,
    fetchOrgById,
    fetchClassById,
    fetchOrgs,
    fetchOrgsWithSearch,
    fetchClassesByOrgId,
    fetchClassesByOrgIdWithSearch,
    fetchClassesByStaffMemberId,
    fetchClassesByStaffMemberIdWithSearch,
    fetchClassesByStudentId,
    fetchClassesByStudentIdWithSearch,
    fetchAdmins,
    fetchAdminsWithSearch,
    fetchStaffByOrgId,
    fetchStaffByOrgIdWithSearch,
    fetchStaffByClassId,
    fetchStaffByClassIdWithSearch,
    fetchStaffByTestId,
    fetchStaffByTestIdWithSearch,
    fetchStudentsByOrgId,
    fetchStudentsByOrgIdWithSearch,
    fetchStudentsByClassId,
    fetchStudentsByClassIdWithSearch,
    fetchStudentsByStaffMemberId,
    fetchStudentsByStaffMemberIdWithSearch,
    fetchAllStudentsByClassId,
    fetchAllResultsByTestId,
    fetchTests,
    fetchTestsByClassId,
    fetchTestsByClassIdWithSearch,
    fetchTestsByStudentId,
    fetchTestsByStudentIdWithSearch,
    fetchTestsByStaffMemberId,
    fetchResults,
    fetchResultsByTestId,
    fetchResultsByStudentId,
    fetchDashboardAggregations,
    fetchClassFeedback,
    addOrg,
    addClass,
    inviteAdmin,
    inviteStaffMember,
    addStudent,
    upsertResult,
    generateTest,
    updateOrg,
    updateClass,
    updateAdmin,
    updateStaffMember,
    updateStudent,
    updateTest,
    completeTest,
    patchStudentClasses,
    deleteStudentClasses,
    deleteAdmin,
    deleteOrg,
    deleteClass,
    deleteStaffMember,
    deleteStudent,
    deleteTest,
    downloadTestPDF,
    downloadImportTemplateCSV,
    downloadPDF,
    downloadCSV,
  }
}

