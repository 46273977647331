import { Box, Button, Center, Input, Text, VStack } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { BiUpload } from "react-icons/bi";
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_WHITE } from "../../../theme";

interface Props {
  setData: Function
}

export const CSVUpload = (props: Props) => {

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null)

  const onClick = () => {
    if (!hiddenFileInput || !hiddenFileInput.current) {
      return
    }
    hiddenFileInput.current.click();
  }

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget || !e.currentTarget.files) {
      return
    }
    var f = e.currentTarget.files[0];
    setFile(f)
    var reader = new FileReader();
    reader.onload = function() {
      let content = reader.result as string
      parseFileData(content)
    }
    reader.readAsText(f);
  };

  const parseFileData = (d: string | null) => {
    if (!d) {
      return
    }
    var arr = d.split("\r\n");
    if (arr[0] !== 'identifier,preferredName,lastName,yearLevel') {
      setError("Invalid format")
      return
    } else if (error) {
      setError(null)
    }
    var items = []
    for (var i = 1; i < arr.length; i++) {
      if (arr[i] === "") {
        continue;
      }
      var row = arr[i].split(",")
      var entry = {
        identifier: row[0],
        preferredName: row[1],
        lastName: row[2],
        yearLevel: parseInt(row[3]),
      }
      items.push(entry)
    }
    props.setData(items)
  }

  return (
    <Box py={10}>
      <Button
        h={200}
        w="full"
        background={COLOUR_PRIMARY_WHITE}
        border={BORDER_DEFAULT}
        borderRadius={10}
        test-id="choose-file-button"
        onClick={onClick}>
        <Center>
          <VStack>
            <BiUpload color={COLOUR_PRIMARY_DARK} size={30} />
            <Text color={COLOUR_PRIMARY_DARK}>Click to select a CSV file</Text>
            {error && <Text color="red">{error}</Text>}
          </VStack>
        </Center>
        <Input test-id="choose-file-input" type="file" accept=".csv" ref={hiddenFileInput} onChange={handleOnChange} hidden />
      </Button>
    </Box>
  );
}
