import React from 'react';

export interface RTableColumnProps {
  id?: string;
  name: string;
  format?: string;
  component?: Function;
  isViewable?: boolean
  editable?: boolean
}

export const RTableColumn: React.FC<RTableColumnProps> = () => {
  return <></>
}
