import { Field, FieldInputProps, FieldProps, useFormikContext } from "formik"
import React, { } from 'react'
import { Control } from "../control"
import { FormError } from "../error"
import { validateDate } from "../../../data/validations"
import { Label } from "./Label"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css"
import { RAInputProps } from "./RAInputProps"

export interface DateTimeInputProps extends RAInputProps {
}

export const DateTimeInput = (props: DateTimeInputProps) => {

  const { setFieldValue } = useFormikContext()

  const toDate = (field: FieldInputProps<any>) => {
    return new Date(field.value)
  }

  const name = props.name || DateTimeInput.defaultProps.name

  return (
    <Field
      name={name}
      validate={props.validation || validateDate}>
      {({ field, form }: FieldProps) => (
        <Control
          form={form}
          name={name}
        >

          {props.title && <Label optional={props.optional || false} title={props.title} />}

          <DatePicker
            wrapperClassName="datePicker"
            selected={toDate(field)}
            onChange={(date) => setFieldValue(field.name, date)}
            dateFormat="Pp"
            showTimeSelect
          />

          {props.name &&
            <FormError
              form={form}
              name={name}
            />}

        </Control>
      )}
    </Field>
  )
}

DateTimeInput.defaultProps = {
  type: "datetime",
  name: "datetime",
};
