import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AnalyticaTable } from '../../table';
import { AccountContext } from '../../Account';
import { Class, Org, StaffMember } from '../../../data/classes';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { Loading } from '../../loading';
import { ICON_CLASS } from '../../../theme';
import { AddButton } from '../../button';
import { RTableColumn, RTableColumns } from '../../table/column';
import { RTableActions } from '../../table/action';
import { ClassModal } from '../../modal/class';
import { PageController } from '../../controller/page-controller';
import { ModalContext } from '../../Modal';
import { useRequests } from '../../../data/requests';

export const Classes = () => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchClassesByStaffMemberId, fetchClassesByStaffMemberIdWithSearch, fetchOrgById, fetchStaffMemberByCognitoId } = useRequests()

  let { orgId } = useParams();
  const [org, setOrg] = useState<Org | null>()
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)

  const { getCognitoId, isStaff, setPageTitle } = useContext(AccountContext)

  const userIsStaff = isStaff()
  const cognitoId = getCognitoId()

  useEffect(() => {
    setPageTitle("Classes")
    let oId = orgId
    if (userIsStaff && !staffMember) {
      fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
    }
    if (staffMember) {
      oId = staffMember.orgId
    }
    if (oId) {
      fetchOrgById(oId, setOrg)
    }
  }, [setPageTitle, orgId, staffMember, cognitoId, userIsStaff, fetchStaffMemberByCognitoId, fetchOrgById]);

  if (!org || !staffMember) {
    return <Loading />
  }

  const fetchClassesWithSearch = (search: string, setItems: (c: Class[]) => void, start: number, limit: number) =>
    fetchClassesByStaffMemberIdWithSearch(staffMember.id, search, setItems, start, limit);

  const fetchClasses = (setItems: (c: Class[]) => void, start: number, limit: number) =>
    fetchClassesByStaffMemberId(staffMember.id, setItems, start, limit);

  return (
    <Box
      paddingX={5}
    >
      <PageController>
        <AnalyticaTable
          icon={ICON_CLASS}
          fetchItems={fetchClasses}
          fetchItemsWithSearch={fetchClassesWithSearch}
          searchable
          searchOnChange
          fullscreen
        >
          <RTableActions name='RTableActions'>
            <AddButton
              title="New Class"
              onClick={() => setModalContent(
                <ClassModal mode="add" org={org} staffMember={staffMember} />
              )}
              testId="new-class-button"
            />
          </RTableActions>

          <RTableColumns name='RTableColumns'>
            <RTableColumn id="name" name="Name" />
            <RTableColumn id="yearLevel" name="Year Level" />
            <RTableColumn id="numStudents" name="#Students" />
            <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
            <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
          </RTableColumns>

        </AnalyticaTable>
      </PageController>
    </Box>
  )
}
