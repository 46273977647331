import { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../Account';
import React from 'react';
import { Loading } from '../loading';
import { AdminDash } from './admin-dash';
import { StaffDash } from './staff-dash';
import { Box } from '@chakra-ui/react';
import { useRequests } from '../../data/requests';

export interface Data {
  avgProgress: object[]
  topLearners: object[]
  totalClasses: number
  totalStudents: number
  testsLastMonth: number
  avgScore: number
}

export interface DashboardProps {
}

export const Dashboard: React.FC<DashboardProps> = () => {

  const { fetchDashboardAggregations } = useRequests()

  const [data, setData] = useState<Data>()
  const { setPageTitle, isAdmin, isStaff } = useContext(AccountContext)

  const userIsAdmin = isAdmin()
  const userIsStaff = isStaff()

  useEffect(() => {
    fetchDashboardAggregations(setData)
    setPageTitle("Dashboard")
  }, [fetchDashboardAggregations, setPageTitle]);

  if (!data) {
    return <Loading />
  }

  if (!userIsAdmin && !userIsStaff) {
    throw new Error("User does not have permissions")
  }
  return (
    <Box>
      {userIsAdmin && <AdminDash data={data} />}
      {userIsStaff && <StaffDash data={data} />}
    </Box>
  )
}

export default Dashboard;

