import { Heading, Text, HStack, Divider, VStack } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom';
import { AccountContext } from '../Account';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Loading } from '../loading';
import { Org, StaffMember } from '../../data/classes';
import { COLOUR_PRIMARY_WHITE, ICON_ADMIN, ICON_CLASS, ICON_DASHBOARD, ICON_ORG, ICON_STAFF, ICON_STUDENT, ICON_TEST } from '../../theme';
import { useRequests } from '../../data/requests';

export const Sidebar = () => {

  const { getCognitoId, isAdmin, isStaff } = useContext(AccountContext)
  const { fetchOrgById, fetchStaffMemberByCognitoId } = useRequests()

  const [org, setOrg] = useState<Org | null>(null)
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)

  const userIsAdmin = isAdmin()
  const userIsStaff = isStaff()
  const cognitoId = getCognitoId()

  useEffect(() => {
    if (userIsStaff) {
      if (staffMember) {
        fetchOrgById(staffMember.orgId, setOrg)
      } else {
        fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
      }
    }
  }, [staffMember, cognitoId, userIsStaff, fetchOrgById, fetchStaffMemberByCognitoId]);

  if (userIsStaff && !org) {
    return <Loading />
  }

  return (
    <VStack
      justify="flex-start"
      align="flex-start"
      spacing={5}
      bg={COLOUR_PRIMARY_WHITE}
      p={6}
    >

      <Heading size="lg">Rubric Analytica</Heading>
      <Divider borderWidth="1px" borderColor="#D5D5D5" />

      <NavLink to="/home" test-id="dash-nav">
        <HStack>
          {ICON_DASHBOARD}
          <Text pl={3} fontSize="lg">Dashboard</Text>
        </HStack>
      </NavLink>

      {userIsAdmin &&
        <>
          <NavLink to="/orgs" test-id="org-nav">
            <HStack>
              {ICON_ORG}
              <Text pl={3} fontSize="lg">Organizations</Text>
            </HStack>
          </NavLink>

          <NavLink to="/admins" test-id="admin-nav">
            <HStack>
              {ICON_ADMIN}
              <Text pl={3} fontSize="lg">Admins</Text>
            </HStack>
          </NavLink>
        </>
      }

      {userIsStaff &&
        <>
          { /* TODO: This should probably not rely on the org id in the url. */}
          <NavLink to={`/orgs/${org ? org.id : ""}/classes`} test-id="classes-nav">
            <HStack>
              {ICON_CLASS}
              <Text pl={3} fontSize="lg">Classes</Text>
            </HStack>
          </NavLink>
          <NavLink to={`/orgs/${org ? org.id : ""}/students`} test-id="students-nav">
            <HStack>
              {ICON_STUDENT}
              <Text pl={3} fontSize="lg">Students</Text>
            </HStack>
          </NavLink>
          <NavLink to={`/orgs/${org ? org.id : ""}/staff`} test-id="staff-nav">
            <HStack>
              {ICON_STAFF}
              <Text pl={3} fontSize="lg">Staff</Text>
            </HStack>
          </NavLink>
          <NavLink to={`/orgs/${org ? org.id : ""}/tests`} test-id="test-nav">
            <HStack>
              {ICON_TEST}
              <Text pl={3} fontSize="lg">Tests</Text>
            </HStack>
          </NavLink>
        </>
      }
    </VStack >
  )
}
