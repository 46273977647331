import React, { ReactElement, useContext, useState } from "react";
import { Student } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { AddButton } from "../../../button";
import { ModalContext } from "../../../Modal";
import { StudentModal } from "../../../modal/student";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { OrgTableTabProps } from "../org-profile";

export const OrgStudents: React.FC<OrgTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchStudentsByOrgId, fetchStudentsByOrgIdWithSearch } = useRequests()
  const [render] = useState<boolean>(false)

  const fetchStudentsWithSearch = (search: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByOrgIdWithSearch(props.org.id, search, setItems, start, limit);
  }

  const fetchStudents = (setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByOrgId(props.org.id, setItems, start, limit);
  }

  const fullnameComponent = (column: ReactElement<RTableColumnProps>, s: Student) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStudents}
        fetchItemsWithSearch={fetchStudentsWithSearch}
        render={render}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/students"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="New Student"
            testId="new-student-button"
            onClick={() => setModalContent(
              <StudentModal
                mode="add"
                org={props.org}
              />
            )}
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullnameComponent} />
          <RTableColumn id="level" name="Level" />
          <RTableColumn id="yearLevel" name="yearLevel" />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>

      </AnalyticaTable>
    </>
  )
}
