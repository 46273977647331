import { CognitoUserSession } from "amazon-cognito-identity-js";
import axios, { AxiosError } from "axios";

export const createAxiosInstance = (session: CognitoUserSession | undefined) => {

  if (session === undefined) {
    throw new Error("No session available for axios")
  }
  const axiosInstance = axios.create();

  // Request interceptor to attach the token
  axiosInstance.interceptors.request.use(
    async (config) => {
      try {
        const token = session?.getIdToken().getJwtToken();
        config.headers['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        console.error(error)
      }
      return config;
    });

  // Response interceptor to handle 401 errors
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (error.response?.status === 401) {
        try {
          const newToken = session?.getIdToken().getJwtToken(); // Get the new token

          // Retry the failed request with the new token
          if (error.config) {
            error.config.headers['Authorization'] = `Bearer ${newToken}`;
            return axiosInstance.request(error.config);
          }
        } catch (refreshError) {
          throw new Error('Token refresh failed: ' + refreshError);
          // window.location.href = '/login'; // Redirect to login
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
