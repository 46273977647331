import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { AccountContext } from "./Account";
import React from 'react'
import { Loading } from "./loading";
import { Sidebar } from "./sidebar";
import { Header } from "./header";
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT } from "../theme";

const R = require('ramda');

interface Props {
  roles: string[]
}

/*
 * This function is used to prevent the user from accessing 
 * pages that they do not have the permissions to access.
 * The Account function is used to handle invalid sessions, 
 * this is only for handling the routes.
 */
export const ProtectedRoute: React.FC<Props> = ({ roles }) => {

  const navigate = useNavigate();
  const { session, getSession, getUserRoles } = useContext(AccountContext)

  const containsAtLeastOne = (userRoles: string[], requiredRoles: string[]) => {
    return R.intersection(userRoles, requiredRoles).length > 0;
  }

  useEffect(() => {
    if (!session?.isValid()) {
      getSession()
      return
    }
  }, [session, getSession, navigate])

  if (!session?.isValid())
    return <Loading />

  const userRoles = getUserRoles()
  if (!containsAtLeastOne(userRoles, roles)) {
    navigate("/404", { replace: true });
    return <Loading />
  }

  return (
    <Grid
      minHeight='100vh'
      templateRows='repeat(11, 1fr)'
      templateColumns='repeat(7, 1fr)'
      bg={COLOUR_PRIMARY_LIGHT}
    >
      <GridItem rowSpan={11} colSpan={1} bg='white' borderRight={BORDER_DEFAULT}>
        <Sidebar />
      </GridItem>
      <GridItem rowSpan={1} colSpan={6}>
        <Header />
      </GridItem>
      <GridItem rowSpan={10} colSpan={6} >
        <Box>
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  )
};
