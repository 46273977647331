import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react';
import { AnalyticaForm } from '../../form';
import { IntegerInput, TextInput } from '../../form/input';
import { Class, Org, StaffMember, Student } from '../../../data/classes';
import { useRequests } from '../../../data/requests';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { useNavigate } from 'react-router-dom';
import { ModalMode } from '../../Modal';

const R = require('ramda')

export type ClassModalMode = ModalMode | 'students';

export interface ClassModalProps {
  org: Org
  clazz?: Class
  staffMember?: StaffMember
  mode: ClassModalMode
}

export const ClassModal: React.FC<ClassModalProps> = ({ mode, clazz, ...props }) => {

  const navigate = useNavigate()
  const deleteAndReturn = (clazz: Class, callback: () => void) => {
    deleteClass(clazz, callback)
    navigate(`/orgs/${props.org.id}`)
  }

  const { fetchStudentsByClassId, addClass, deleteClass, updateClass, updateStudent } = useRequests()
  const [students, setStudents] = useState<Student[]>();

  const updateClassStudents = (values: any, callback: () => void) => {
    if (!clazz) {
      throw new Error("Class prop not supplied")
    }

    // const findUpdatedItems = (reference: Student[], updated: Student[]): Student[] =>
    //   R.differenceWith(R.eqProps("id"), updated, reference);

    // Apply Ramda's filter function to keep only updated items
    const updated = R.filter(
      (item: Student) => !students?.some((ref: Student) => R.eqProps("id", ref, item)
        && ref.identifier === item.identifier
        && ref.preferredName === item.preferredName
        && ref.lastName === item.lastName
        && ref.yearLevel === item.yearLevel
      ),
      values.students
    );
    // patch updated students
    if (updated && updated.length > 0) {
      R.forEach((s: Student) => {
        console.log("Updating: ", s)
        updateStudent(s, () => { })
      }, updated)
    }
    callback?.();
  }

  useEffect(() => {
    if (mode !== 'students') {
      return
    }
    if (clazz === undefined) {
      throw new Error("Class is undefined, cannot fetch students")
    }
    fetchStudentsByClassId(clazz.id, setStudents, 0, -1);
  }, [clazz?.id]);

  let title
  let description
  let initialValues

  switch (mode) {
    case "add":
      title = "Add a new Class"
      description = `Add a new Class to ${props.org.name}`
      initialValues = {
        orgId: props.org.id,
        staff: props.staffMember && [props.staffMember.id]
      }
      break
    case "update":
      if (!clazz) {
        throw new Error("Item not supplied");
      }
      title = "Edit Class"
      description = "Update Class Details."
      initialValues = clazz
      break
    case "delete":
      if (!clazz) {
        throw new Error("Item not supplied");
      }
      title = "Delete Class"
      description = `Are you sure you want to delete Class - ${clazz.name}?`
      initialValues = { id: clazz.id }
      break
    case "students":
      if (!clazz) {
        throw new Error("Item not supplied");
      }
      title = "Edit Class Students"
      description = `Edit multiple students in Class - ${clazz.name}?`
      initialValues = { students: students || [] }
      break
  }

  return (
    <AnalyticaForm
      name='class-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={mode === 'students' ? 'update' : mode}
      onSubmit={
        mode === 'add' ? addClass :
          mode === 'update' ? updateClass
            : mode === 'delete' ? deleteAndReturn
              : updateClassStudents}
    >
      {
        R.includes(mode, ['add', 'update']) &&
        <AnalyticaForm.SingleItemForm>
          <TextInput
            name="name"
            title="Name"
            testId='class-name-input'
            validation={validateName}
          />
          <Box pt={4} />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='class-year-level-input'
            validation={validateNonEmpty}
            min={1}
            max={12}
          />
          {/* <MultiSelectionInput */}
          {/*   name="staff" */}
          {/*   title="Staff" */}
          {/*   testId='class-staff-input' */}
          {/*   validation={validateMultiSelection} */}
          {/*   fetchItems={fetchDropdownStaffWithSearch} */}
          {/* /> */}
        </AnalyticaForm.SingleItemForm>
      }
      {mode === 'students' &&
        <AnalyticaForm.TableForm<Student>
          fieldKey="students"
        >
          <TextInput
            name="identifier"
            title="Identifier"
            testId='student-identifier-input'
            validation={validateNonEmpty}
          />
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='student-preferred-name-input'
            validation={validateName}
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='student-last-name-input'
            validation={validateName}
          />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='student-year-level-input'
            validation={validateNonEmpty}
            min={1}
            max={12}
          />
        </AnalyticaForm.TableForm>
      }
    </AnalyticaForm>
  )
}
