import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountContext } from "../../Account";
import React from "react";
import { Class, Org, Test } from "../../../data/classes";
import { Loading } from "../../loading";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { TestResults } from "./results";
import { TestStaff } from "./staff";
import { TestModal } from "../../modal/test";
import { RAProfile } from "../profile";
import { testTitle } from "../../../data/functions";
import { ICON_DOWNLOAD, ICON_FLAG, ICON_RESULT, ICON_STAFF } from "../../../theme";
import { PageController } from "../../controller/page-controller";
import { AddButton } from "../../button";
import dayjs from "dayjs";
import { useRequests } from "../../../data/requests";

export interface TestTableTabProps extends AnalyticaTableTabProps {
  org: Org
  test: Test
}

export const TestProfile = () => {

  const { setPageTitle } = useContext(AccountContext)
  const { completeTest, downloadTestPDF, fetchClassById, fetchOrgById, fetchTestById } = useRequests()
  const [org, setOrg] = useState<Org>()
  const [clazz, setClass] = useState<Class>()
  const [test, setTest] = useState<Test>()
  let { orgId, testId } = useParams();

  const loadClass = useCallback((classId: string) => {
    setPageTitle("Tests")
    if (classId) {
      fetchClassById(classId, setClass)
    }
  }, [setPageTitle])

  const load = useCallback(() => {
    if (orgId && testId) {
      fetchOrgById(orgId, setOrg)
      fetchTestById(testId, (t: Test) => {
        setTest(t)
        loadClass(t.classId)
      })
    }
  }, [orgId, testId, loadClass])

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !test || !clazz) {
    return <Loading />
  }

  const download = () => {
    let d = dayjs(new Date(test.created))
    downloadTestPDF(test.id, `${clazz.name}_${d.format("DDMMYY")}.pdf`)
  }

  const endTest = () => {
    completeTest(test, () => load())
  }

  return (
    <PageController>
      <RAProfile
        primary={testTitle(test) || ''}
        secondary={org.name}
        additionalActions={test.completed ? [] : [
          <AddButton
            title="Download Test"
            testId="download-test"
            leftIcon={ICON_DOWNLOAD}
            onClick={() => download()}
          />,
          <AddButton
            title="End Test"
            testId="end-test"
            leftIcon={ICON_FLAG}
            onClick={() => endTest()}
          />
        ]}
        edit={
          <TestModal mode="update" org={org} clazz={clazz} test={test} />
        }
        delete={
          <TestModal mode="delete" org={org} clazz={clazz} test={test} />
        }
      />

      <AnalyticaTableContainer>
        <TestResults title="Results" icon={ICON_RESULT} org={org} test={test} testId="view-results" />
        <TestStaff title="Staff" icon={ICON_STAFF} org={org} test={test} testId="view-staff" />
      </AnalyticaTableContainer>

    </PageController>
  )
}
