// thought this could possibly be undefined too
type idstr = string

export class ID {
  id: idstr;

  constructor(id: idstr) {
    this.id = id
  }
}

export class OptionalID {
  id?: idstr;
}

export class Person extends ID {
  preferredName: string;
  lastName: string;

  constructor(id: idstr, preferredName: string, lastName: string) {
    super(id)
    this.preferredName = preferredName
    this.lastName = lastName
  }
}

export class Named extends ID {
  name: string;

  constructor(id: idstr, name: string) {
    super(id)
    this.name = name
  }
}

export class User extends Person {
  email: string;
  cognitoId: string;

  constructor(id: idstr, preferredName: string, lastName: string, cognitoId: string, email: string) {
    super(id, preferredName, lastName)
    this.cognitoId = cognitoId
    this.email = email
  }
}

export class Admin extends User { }

export class StaffMember extends User {
  orgId: string;
  numClasses: number;
  numStudents: number;

  constructor(id: idstr, preferredName: string, lastName: string, cognitoId: string, email: string, orgId: string, numClasses: number, numStudents: number) {
    super(id, preferredName, lastName, cognitoId, email)
    this.orgId = orgId
    this.numClasses = numClasses
    this.numStudents = numStudents
  }
}

export class Student extends Person {
  orgId: string
  identifier: string;
  yearLevel: number;
  level: number;
  avgScore: number;
  avgTimeRemaining: number;
  numClasses: number;
  numTestsCompleted: number;
  classes: string[];

  constructor(id: idstr, preferredName: string, lastName: string, orgId: string, identifier: string, yearLevel: number, level: number, avgScore: number, avgTimeRemaining: number, numClasses: number, numTestsCompleted: number, classes: string[]) {
    super(id, preferredName, lastName)
    this.orgId = orgId
    this.identifier = identifier
    this.yearLevel = yearLevel
    this.level = level
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numClasses = numClasses
    this.numTestsCompleted = numTestsCompleted
    this.classes = classes
  }
}

export class Org extends Named {
  primaryAddress: string;
  avgScore: number;
  avgTimeRemaining: number;
  numClasses: number;
  numStudents: number;
  numTestsCompleted: number;

  constructor(id: idstr, name: string, primaryAddress: string, avgScore: number, avgTimeRemaining: number, numClasses: number, numStudents: number, numTestsCompleted: number) {
    super(id, name)
    this.primaryAddress = primaryAddress
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numClasses = numClasses
    this.numStudents = numStudents
    this.numTestsCompleted = numTestsCompleted
  }
}

export class Class extends Named {
  orgId: string;
  avgScore: number;
  avgTimeRemaining: number;
  numStudents: number;
  numTestsCompleted: number;
  students: string[];

  constructor(id: idstr, name: string, orgId: string, avgScore: number, avgTimeRemaining: number, numStudents: number, numTestsCompleted: number, students: string[]) {
    super(id, name)
    this.orgId = orgId
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numStudents = numStudents
    this.numTestsCompleted = numTestsCompleted
    this.students = students
  }
}

export class Test extends ID {
  classId: idstr;
  created: string;
  datetime: string;
  completed: boolean;

  constructor(
    id: idstr,
    classId: string,
    created: string,
    datetime: string,
    completed: boolean
  ) {
    super(id)
    this.classId = classId
    this.created = created
    this.datetime = datetime
    this.completed = completed
  }
}

export class Result extends OptionalID {
  orgId: idstr;
  classId: idstr;
  testId: idstr;
  studentId: idstr;
  identifier: string;
  completed: boolean;
  score: number;
  timeRemaining: number;
  active: boolean;
  name?: string;
  level?: number;

  constructor(
    orgId: idstr,
    classId: idstr,
    testId: idstr,
    studentId: idstr,
    identifier: string,
    completed: boolean,
    score: number,
    timeRemaining: number,
    active: boolean,
    name?: string,
    level?: number,
    id?: idstr,
  ) {
    super()
    this.orgId = orgId
    this.classId = classId
    this.testId = testId
    this.studentId = studentId
    this.identifier = identifier
    this.score = score
    this.timeRemaining = timeRemaining
    this.completed = completed
    this.active = active
    this.name = name
    this.level = level
    this.id = id
  }
}

export class StudentAndResult {
  student: Student;
  result: Result;

  constructor(student: Student, result: Result) {
    this.student = student
    this.result = result
  }
}
