import { Tag } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { BORDER_DEFAULT, ICON_STAFF } from "../../../../theme";
import { AddButton } from "../../../button";
import { AnalyticaTable } from "../../../table";
import { ClassTableTabProps } from "../class-profile";
import { Test } from "../../../../data/classes";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableActions } from "../../../table/action";
import { testTitle } from "../../../../data/functions";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { useRequests } from "../../../../data/requests";

export const ClassTests: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const { fetchTestsByClassId, fetchTestsByClassIdWithSearch, generateTest, } = useRequests()

  const fetchTestsWithSearch = (search: string, setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByClassIdWithSearch(props.clazz.id, search, setItems, start, limit);
  }

  const fetchTests = (setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByClassId(props.clazz.id, setItems, start, limit);
  }

  const generateTestForClass = (classId: string) => {
    try {
      // TODO: need to ensure we get a rerender after this is executed
      generateTest(classId, props.callback ? props.callback : () => { })
    } catch (error) {
    }
  }

  const testTitleComponent = (column: ReactElement<RTableColumnProps>, t: Test) => {
    console.log("Test", t)
    return testTitle(t)
  }

  const testStatus = (column: ReactElement<RTableColumnProps>, t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchTests}
        fetchItemsWithSearch={fetchTestsWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/tests"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="Generate Test"
            testId="generate-test-button"
            onClick={() => generateTestForClass(props.clazz.id)}
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Datetime" component={testTitleComponent} />
          <RTableColumn name="Status" component={testStatus} />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>
      </AnalyticaTable>
    </>
  )
}
