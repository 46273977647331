import { useRequests } from '../../../data/requests';
import React from 'react'
import { AnalyticaForm } from '../../form';
import { validateName } from '../../../data/validations';
import { TextInput } from '../../form/input';
import { Org } from '../../../data/classes';
import { useNavigate } from 'react-router-dom';
import { ModalMode } from '../../Modal';

export interface OrgModalProps {
  org?: Org
  mode: ModalMode
}

export const OrgModal = ({ ...props }) => {

  const navigate = useNavigate()
  const deleteAndReturn = (org: Org, callback: () => void) => {
    deleteOrg(org, callback)
    navigate('/orgs')
  }

  const { addOrg, updateOrg, deleteOrg } = useRequests()

  let title = "Modal Title Placeholder"
  let description = "Modal description Placeholder"
  let initialValues

  switch (props.mode) {
    case "add":
      title = "Add an Organisation"
      description = "Add a new Organisation to Rubric Analytica."
      initialValues = {}
      break
    case "update":
      if (!props.org) {
        throw new Error("Item not supplied");
      }
      title = "Edit Organisation"
      description = "Update Organisation Details."
      initialValues = props.org
      break
    case "delete":
      if (!props.org) {
        throw new Error("Item not supplied");
      }
      title = "Delete Organisation"
      description = `Are you sure you want to delete Organisation - ${props.org.name}?`
      initialValues = { id: props.org.id }
      break
  }

  return <AnalyticaForm
    name="org-modal"
    title={title}
    description={description}
    initialValues={initialValues}
    mode={props.mode}
    onSubmit={
      props.mode === 'update' ? updateOrg
        : props.mode === 'delete' ? deleteAndReturn
          : addOrg}
  >
    {
      props.mode !== 'delete' &&
      <AnalyticaForm.SingleItemForm>
        < TextInput
          name="name"
          testId="org-name-input"
          title="Organisation Name"
          validation={validateName}
        />
        <TextInput
          name="primaryAddress"
          testId="org-primary-address-input"
          title="Primary Address"
          optional
        />
      </AnalyticaForm.SingleItemForm>
    }
  </AnalyticaForm>
}
