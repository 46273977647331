import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Class, Org, Test } from '../../../data/classes';
import { testTitle } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { validateDate } from '../../../data/validations';
import { AccountContext } from '../../Account';
import { AnalyticaForm } from '../../form';
import { DateTimeInput } from '../../form/input/DateTimeInput';
import { ModalMode } from '../../Modal';

export interface TestModalProps {
  org: Org
  clazz: Class
  test: Test
  mode: ModalMode
}

export const TestModal: React.FC<TestModalProps> = ({ ...props }) => {

  const { isAdmin } = useContext(AccountContext)
  const userIsAdmin = isAdmin()

  const navigate = useNavigate()
  const deleteAndReturn = (test: Test, callback: () => void) => {
    deleteTest(test, callback)
    navigate(userIsAdmin ? `/orgs/${props.org.id}/classes/${props.clazz.id}` : '/tests')
  }

  const { deleteTest, updateTest } = useRequests()

  let title
  let description
  let initialValues

  switch (props.mode) {
    case "add":
      throw new Error("Invalid mode for Test Modal!")
    case "update":
      if (!props.test) {
        throw new Error("Item not supplied");
      }
      title = "Edit Test"
      description = "Update Test Details."
      initialValues = props.test
      break
    case "delete":
      if (!props.test || !props.clazz) {
        throw new Error("Item not supplied");
      }
      title = "Delete Test"
      description = `Are you sure you want to delete Test - ${testTitle(props.test)}?`
      initialValues = { id: props.clazz.id }
      break
  }

  return (
    <AnalyticaForm
      name='class-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode}
      onSubmit={
        props.mode === 'update' ? updateTest
          : props.mode === 'delete' ? deleteAndReturn
            : () => { }}
    >
      {
        props.mode !== 'delete' &&
        <AnalyticaForm.SingleItemForm>
          <DateTimeInput
            name="datetime"
            title="datetime"
            testId='test-datetime-input'
            validation={validateDate}
          />
        </AnalyticaForm.SingleItemForm>
      }
    </AnalyticaForm>
  )
}
