import React, { ReactNode, createContext, useEffect, useState, useCallback, useContext } from "react"
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js"
import { useNavigate } from "react-router-dom";

declare const window: any;

// interface CurrentUser {
//   [key: string]: any
// }

interface AccountContextType {
  session: CognitoUserSession | undefined,
  getSession: Function,
  signUp: Function,
  verify: Function,
  resetPassword: Function,
  resendConfirmationCode: Function,
  authenticate: Function,
  updateDetails: Function,
  logout: Function,
  getCognitoId: Function,
  getUserRoles: Function,
  isAdmin: Function,
  isStaff: Function,
  pageTitle: string,
  setPageTitle: Function,
}

export const AccountContext = createContext<AccountContextType>({} as AccountContextType);
const GROUPS = "cognito:groups"

const poolData = {
  ClientId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_CLIENT_ID : process.env.REACT_APP_COGNITO_CLIENT_ID,
  UserPoolId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_USER_POOL_ID : process.env.REACT_APP_COGNITO_USER_POOL_ID
}

export interface AccountProps {
  children: ReactNode | ReactNode[]
}

export const Account: React.FC<AccountProps> = (props: AccountProps) => {

  const [session, setSession] = useState<CognitoUserSession>()
  const [userPool] = useState<CognitoUserPool>(new CognitoUserPool(poolData))
  const [pageTitle, setPageTitle] = useState<string>("Dashboard")

  const navigate = useNavigate()

  // const checkSessionValidity = () => {
  //   if (!session?.isValid()) {
  //     throw new Error("Session is invalid")
  //   }
  // }

  const checkUserIsValid = useCallback(() => {
    if (!userPool.getCurrentUser()) {
      throw new Error("Current user is invalid")
    }
  }, [userPool])

  const signUp = async (
    Email: string,
    Password: string,
    GivenName: string,
    FamilyName: string,
    onSuccess: Function,
    onError: Function
  ) => {
    userPool.signUp(Email, Password, [
      new CognitoUserAttribute({ Name: "given_name", Value: GivenName }),
      new CognitoUserAttribute({ Name: "family_name", Value: FamilyName }),
    ], [], (err, data) => {
      if (err) {
        onError(err)
      }
      onSuccess(data)
    })
  }

  const authenticate = async (
    Email: string,
    Password: string,
    onSuccess: Function,
    onError: Function,
    onNewPasswordRequired: Function
  ) => {
    const u = new CognitoUser({ Username: Email, Pool: userPool })
    const authDetails = new AuthenticationDetails({ Username: Email, Password })
    u.authenticateUser(authDetails, {
      onSuccess: (data) => {
        getSession()
        onSuccess(data)
      },
      onFailure: (err) => {
        onError(err)
      },
      newPasswordRequired: () => {
        getSession()
        onNewPasswordRequired()
      },
    })
  }

  const verify = async (
    Code: string,
    onSuccess: Function,
    onError: Function
  ) => {
    checkUserIsValid()
    userPool.getCurrentUser()?.confirmRegistration(Code, true, (err: Error, data) => {
      if (err) {
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const resetPassword = async (
    NewPassword: string,
    onSuccess: Function,
    onError: Function
  ) => {
    checkUserIsValid()
    userPool.getCurrentUser()?.completeNewPasswordChallenge(NewPassword, {}, {
      onSuccess: (data) => {
        onSuccess(data)
      },
      onFailure: (err) => {
        onError(err)
      },
    })
  }

  const resendConfirmationCode = async (
    onSuccess: Function,
    onError: Function
  ) => {
    checkUserIsValid()
    userPool.getCurrentUser()?.resendConfirmationCode((err: Error | undefined, data: any) => {
      if (err) {
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const updateDetails = async (
    GivenName: string,
    FamilyName: string,
    onSuccess: Function,
    onError: Function
  ) => {
    checkUserIsValid()
    userPool.getCurrentUser()?.updateAttributes([
      new CognitoUserAttribute({ Name: "given_name", Value: GivenName }),
      new CognitoUserAttribute({ Name: "family_name", Value: FamilyName }),
    ], (err, data) => {
      if (err) {
        console.error(err)
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const logout = () => {
    userPool.getCurrentUser()?.signOut()
    setSession(undefined)
    localStorage.clear()
    return navigate("/login", { replace: true });
  }

  const getCognitoId = () => {
    checkUserIsValid()
    let user = session?.getIdToken().payload
    return user?.sub
  }

  const getUserRoles = () => {
    checkUserIsValid()
    let user = session?.getIdToken().payload
    return (user && user[GROUPS]) ? user[GROUPS] : []
  }

  const isAdmin = () => {
    checkUserIsValid()
    let user = session?.getIdToken().payload
    return user && user[GROUPS] && user[GROUPS].includes("admin")
  }

  const isStaff = () => {
    checkUserIsValid()
    let user = session?.getIdToken().payload
    return user && user[GROUPS] && user[GROUPS].includes("staff")
  }

  const refreshSession = useCallback(() => {
    checkUserIsValid()
    if (session?.isValid()) {
      return
    }
    userPool.getCurrentUser()?.getSession((err: any, session: CognitoUserSession) => {
      if (err) {
        console.error("Error getting session:", err);
        return navigate("/login", { replace: true });
      }
      // grab refresh token
      const refreshToken = session.getRefreshToken();
      if (!refreshToken) {
        console.error("No refresh token available. Redirecting to login.");
        return navigate("/login", { replace: true });
      }
      // refresh the session
      userPool.getCurrentUser()?.refreshSession(refreshToken, (err, newSession) => {
        if (err) {
          console.error("Failed to refresh session:", err);
          return navigate("/login", { replace: true });
        }
        console.log("Session refreshed successfully. New tokens:", newSession);
      });
    });
  }, [checkUserIsValid, navigate, session, userPool])

  const checkUserIsVerified = useCallback(() => {
    let verified = session?.getIdToken().payload.email_verified
    if (!verified) {
      return navigate("/verify", { replace: true });
    }
  }, [session, navigate])

  const getSession = useCallback(
    async () => {
      try {
        checkUserIsValid()
      } catch (err) {
        return navigate("/login", { replace: true });
      }
      userPool.getCurrentUser()?.getSession((err: Error, sess: CognitoUserSession | null) => {
        if (err) {
          return navigate("/login", { replace: true });
        }
        setSession(sess ? sess : undefined)
        checkUserIsVerified()
        return navigate("/home", { replace: true });
      })

    }, [userPool, checkUserIsValid, navigate, checkUserIsVerified])

  useEffect(() => {
    if (!session?.isValid()) {
      getSession()
      return
    }
    checkUserIsVerified()

    // Refresh session every 15 minutes (or a suitable interval for your app)
    const interval = setInterval(refreshSession, 15 * 60 * 1000);
    return () => clearInterval(interval);

  }, [session, getSession, navigate, checkUserIsVerified, refreshSession])

  return (
    <AccountContext.Provider value={{
      session,
      getSession,
      signUp,
      verify,
      resetPassword,
      resendConfirmationCode,
      authenticate,
      updateDetails,
      logout,
      getCognitoId,
      getUserRoles,
      isAdmin,
      isStaff,
      pageTitle,
      setPageTitle,
    }}>
      {props.children}
    </AccountContext.Provider>
  )
}

export const useAccount = (): AccountContextType => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within a AccountProvider');
  }
  return context;
}
