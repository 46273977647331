import { useContext, useEffect, useState } from "react"
import { Center, Text } from "@chakra-ui/react"
import { AccountContext } from "./Account"
import { useLocation, useNavigate } from "react-router-dom";
import React from 'react'
import { AuthForm } from "./form";
import { validateNonEmpty } from "../data/validations";
import { TextInput } from "./form/input";

export const Verify = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null)

  const { session, getSession, verify, resendConfirmationCode } = useContext(AccountContext)

  useEffect(() => {
    if (!session?.isValid()) {
      getSession()
      return
    } else {
      return navigate("/home", { replace: true });
    }
  }, [session, getSession, navigate])

  const onSuccess = () => {
    navigate("/login", { replace: true, state: { msg: "Success! Please Login." } });
  }

  const onError = (err: Error) => {
    setError(err.name === "CodeMismatchException" ? "Invalid Code" : "Error verifying account")
  }

  const onSubmit = (values: any) => {
    verify(location.state.email, values.verificationCode, onSuccess, onError);
  }

  const onResend = (event: Event) => {
    event.preventDefault()
    resendConfirmationCode(location.state.email, onMoveToHomePage, (err: Error) => console.log(err))
  }

  const onMoveToHomePage = () => {
    navigate("/home", { replace: true });
  }

  return (
    <AuthForm
      name="verify"
      title="Verify your Account"
      buttonText="Verify"
      error={error}
      onSubmit={onSubmit}
    >
      <Center>
        <Text align="center" opacity="80%" size="sm">You should now receive an email with a verification code.</Text>
      </Center>

      <TextInput
        name="verificationCode"
        title="Verification Code"
        testId="verification-code-input"
        validation={validateNonEmpty}
      />
    </AuthForm>
  )
}

export default Verify
