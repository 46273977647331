import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Center, HStack, Spacer, Text, Flex, Table, TableContainer, Tbody, Thead, Th, Tr, Td, IconButton } from "@chakra-ui/react";
import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import React from 'react'
import { Loading } from "../loading";
import { SearchBar } from "../search";
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_WHITE, COLOUR_PRIMARY_LIGHT, MARGIN_PRIMARY, RADIUS_PRIMARY } from "../../theme";
import { RTableColumnProps } from "./column/r-table-column";
import { useNavigate } from "react-router-dom";
import { RTableActionsProps } from "./action/r-table-actions";
import { RTableColumnsProps } from "./column/r-table-columns";

const R = require('ramda');

export interface AnalyticaTableTabProps extends React.PropsWithChildren {
  title: string
  icon: ReactNode
  testId?: string
  render?: boolean
}

export interface AnalyticaTableProps extends React.PropsWithChildren {
  fetchItems: Function
  fetchItemsWithSearch?: Function
  icon?: ReactNode
  render?: boolean
  searchable?: boolean
  searchOnChange?: boolean
  hidePagination?: boolean
  fullscreen?: boolean
  itemTestId?: Function
  to?: string
  scrollable?: boolean
  editable?: boolean
}

export const AnalyticaTable: React.FC<AnalyticaTableProps> = (
  {
    searchable = false,
    searchOnChange = false,
    hidePagination = false,
    fullscreen = false,
    scrollable = false,
    editable = false,
    fetchItems,
    fetchItemsWithSearch,
    ...props
  }
) => {

  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [items, setItems] = useState<any | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  const navigate = useNavigate()

  const start = useCallback(() => {
    return (page - 1) * limit
  }, [page, limit])

  const loadItems = useCallback(() => {
    const loadData = async () => {
      if (search) {
        if (!fetchItemsWithSearch) {
          throw new Error("No 'fetchItemsWithSearch' function has been provided")
        }
        // additional thought: the search var in this function makes more sense being after setItems.
        await fetchItemsWithSearch(search, setItems, start(), limit)
      } else {
        await fetchItems(setItems, start(), limit)
      }
    };
    loadData()
  }, [setItems, search, start, limit, fetchItems, fetchItemsWithSearch])

  useEffect(() => {
    loadItems()
  }, [loadItems]);

  if (!items) {
    return (
      <Loading />
    )
  }

  let isEmpty = items && R.isEmpty(items)
  let searchBar = <Box w="50%">
    <SearchBar setSearch={setSearch} searchOnChange={searchOnChange} />
  </Box>

  let actionsParent = R.find((child: ReactElement<RTableActionsProps | RTableColumnsProps>) => {
    return React.isValidElement(child) && typeof child.type === "function" && child.props.name === "RTableActions"
  }, props.children)
  let actions = actionsParent && actionsParent.props && actionsParent.props.children

  let columnsParent = R.find((child: ReactElement<RTableActionsProps | RTableColumnsProps>) => {
    return React.isValidElement(child) && typeof child.type === "function" && child.props.name === "RTableColumns"
  }, props.children)
  let columns = columnsParent && columnsParent.props && columnsParent.props.children

  if (!columns) {
    console.error("No Columns Supplied to table!")
    return (
      <Loading />
    )
  }

  let tableHeaderRow = R.map(
    (column: ReactElement<RTableColumnProps>) =>
      <Th key={`column-name-${column.props.name}`} pb={2} px={5} > {column.props.name}</Th >,
    columns)

  const nav = (item: any) => {
    navigate(
      props.to ? (props.to + "/" + item.id) : "./" + item.id
    )
  }

  const value = (column: ReactElement<RTableColumnProps>, value: any) => {
    if (value === undefined || value === '') {
      return "-"
    }
    if (typeof value === 'boolean') {
      return value
    }
    if (value === "true") {
      return true
    }
    if (value === "false") {
      return false
    }
    if (isNaN(value)) {
      return value
    }
    if (column.props.format === "percentage") {
      return `${Number(value * 100).toFixed(1)}%`
    }
    if (Number.isInteger(value)) {
      return value
    }
    return Number(value).toFixed(2)
  }

  const field = (column: ReactElement<RTableColumnProps>, item: any) => {
    if (column.props.component) {
      let v = column.props.component(column, item, items, setItems)
      return value(column, v)
    }
    if (!column.props.id) {
      throw new Error("column is missing an id")
    }
    let v = item[column.props.id]
    return value(column, v)
  }


  var mapIndexed = R.addIndex(R.map);
  let tableRows = mapIndexed((item: any) => {
    return <Tr
      key={item.id}
      test-id={item.id}
      h={50}
      borderTop={BORDER_DEFAULT}
      _hover={{
        backgroundColor: COLOUR_PRIMARY_LIGHT,
        cursor: 'pointer',
      }}
      onClick={!editable ? () => nav(item) : () => { }}
    >
      {props.icon && [<Td key={`${item.id}-icon`} px={0} py={1} pl={5}>{props.icon}</Td>]}
      {
        mapIndexed((column: ReactElement<RTableColumnProps>) => {
          return <Td
            key={`${item.id}-${column.props.id}`}
            p={0}
            px={5}
            test-id={column.props.id}
          >
            {field(column, item)}
          </Td>
        }
          , columns)
      }
    </Tr >
  }, items)

  let table =
    <TableContainer test-id="items-list">
      <Table size="md" variant="unstyled">
        <Thead key="table-head">
          <Tr key="table-header-row">
            {props.icon && [<Th key="icon-blank" />]}
            {tableHeaderRow}
          </Tr>
        </Thead>
        <Tbody>
          {tableRows}
        </Tbody>
      </Table>
    </TableContainer>

  const pageLeft = () => setPage(page - 1)
  const pageRight = () => setPage(page + 1)

  let numberOfResults = <Text>{`Showing Results: ${start() + 1}-${start() + limit}`}</Text>

  let pagination =
    <Box>
      <Flex>
        <Spacer />
        <HStack>
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={pageLeft}
            isDisabled={page === 1}
            bg="transparent"
            color={COLOUR_PRIMARY_DARK}
            aria-label={""} />
          <Text>Page {page}</Text>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={pageRight}
            bg="transparent"
            color={COLOUR_PRIMARY_DARK}
            aria-label={""} />
        </HStack>
      </Flex>
    </Box>

  let noResults =
    <Center>
      <Text m={150}>No Results Found...</Text>
    </Center>

  return (
    <>
      <Box
        w="full"
        h="full"
        borderRadius={RADIUS_PRIMARY}
        borderTopLeftRadius={fullscreen ? RADIUS_PRIMARY : 0}
        bg={COLOUR_PRIMARY_WHITE}
        border={BORDER_DEFAULT}
      >
        <Box
          p={MARGIN_PRIMARY}
          pb={0}
        >
          <HStack>
            {searchable && searchBar}
            <Spacer />
            {actions && actions}
          </HStack>
          {!hidePagination &&
            <HStack
              p={MARGIN_PRIMARY}
            >
              {numberOfResults}
              <Spacer />
              {pagination}
            </HStack>
          }
        </Box>
        <Box overflowY={scrollable ? "auto" : "hidden"}>
          {isEmpty ? noResults : table}
        </Box>
      </Box>
    </>
  )
}
