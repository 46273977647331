import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Org, Class } from "../../../data/classes";
import { useRequests } from "../../../data/requests";
import { ICON_STAFF, ICON_STUDENT, ICON_TEST } from "../../../theme";
import { AccountContext } from "../../Account";
import { PageController } from "../../controller/page-controller";
import { Loading } from "../../loading";
import { ClassModal } from "../../modal/class";
import { StatTile } from "../../stats";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { ClassStaff } from "./staff";
import { ClassStudents } from "./students";
import { ClassTests } from "./tests";

export interface ClassTableTabProps extends AnalyticaTableTabProps {
  org: Org
  clazz: Class
  callback?: () => void
}

export const ClassProfile = () => {

  const { fetchOrgById, fetchClassById } = useRequests()
  const [clazz, setClass] = useState<Class | null>()
  const [org, setOrg] = useState<Org | null>()

  let { orgId, classId } = useParams();

  const { setPageTitle } = useContext(AccountContext)

  const load = useCallback(() => {
    setPageTitle("Classes")
    if (orgId && classId) {
      fetchOrgById(orgId, setOrg)
      fetchClassById(classId, setClass)
    }
  }, [setPageTitle, orgId, classId, fetchOrgById, fetchClassById])

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !clazz) {
    return <Loading />
  }

  return (
    <>
      <PageController>
        <RAProfile
          primary={clazz.name}
          secondary={org.name}
          edit={
            <ClassModal
              mode="update"
              clazz={clazz}
              org={org}
            />
          }
          delete={
            <ClassModal
              mode="delete"
              clazz={clazz}
              org={org}
            />
          }
        >
          <StatTile title="Total Active Students" value={clazz.numStudents} />
          <StatTile title="Average Score Percentage" units="%" value={clazz.avgScore} format="percentage" />
          <StatTile title="Average Time Remaining" units="s" value={clazz.avgTimeRemaining} />
        </RAProfile>

        <AnalyticaTableContainer>
          <ClassStudents title="Students" icon={ICON_STUDENT} org={org} clazz={clazz} testId="view-students" />
          <ClassStaff title="Staff" icon={ICON_STAFF} org={org} clazz={clazz} testId="view-staff" />
          <ClassTests title="Tests" icon={ICON_TEST} org={org} clazz={clazz} callback={() => load()} testId="view-tests" />
        </AnalyticaTableContainer>

      </PageController>
    </>
  )
}
