import { Box, Tag } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ReactElement, useContext, useEffect, useState } from 'react';
import React from 'react'
import { Org, StaffMember, Test } from '../../../data/classes';
import { Loading } from '../../loading/loading';
import { AccountContext } from '../../Account';
import { AnalyticaTable } from '../../table/analytica-table';
import { BORDER_DEFAULT, ICON_TEST } from '../../../theme';
import { RTableColumn, RTableColumns } from '../../table/column';
import { RTableActions } from '../../table/action';
import { PageController } from '../../controller/page-controller';
import { testTitle } from '../../../data/functions';
import { RTableColumnProps } from '../../table/column/r-table-column';
import { useRequests } from '../../../data/requests';

export const Tests = () => {

  const { fetchOrgById, fetchStaffMemberByCognitoId, fetchTestsByStaffMemberId } = useRequests()
  const { getCognitoId, setPageTitle } = useContext(AccountContext)
  const cognitoId = getCognitoId()
  const [org, setOrg] = useState<Org>()
  const [staffMember, setStaffMember] = useState<StaffMember>()
  let { orgId } = useParams()

  useEffect(() => {
    setPageTitle("Tests")
    if (!staffMember) {
      fetchStaffMemberByCognitoId(cognitoId, setStaffMember)
    }
    if (staffMember) {
      fetchOrgById(staffMember.orgId, setOrg)
    }
  }, [setPageTitle, orgId, staffMember, cognitoId, fetchOrgById, fetchStaffMemberByCognitoId]);

  if (!org || !staffMember) {
    return <Loading />
  }

  const fetchTests = (setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByStaffMemberId(staffMember.id, setItems, start, limit);
  }

  const testTitleComponent = (_column: ReactElement<RTableColumnProps>, t: Test) => {
    return testTitle(t)
  }

  const testStatus = (t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <Box
      paddingX={5}
    >
      <PageController>
        <AnalyticaTable
          icon={ICON_TEST}
          fetchItems={fetchTests}
          fullscreen
        >
          <RTableActions name='RTableActions'> </RTableActions>

          <RTableColumns name='RTableColumns'>
            <RTableColumn name="Created" component={testTitleComponent} />
            <RTableColumn name="Status" component={testStatus} />
            <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
            <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
          </RTableColumns>
        </AnalyticaTable>
      </PageController>
    </Box >
  )
}

