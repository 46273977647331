import React, { ReactElement, useContext } from "react";
import { StaffMember } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_STAFF } from "../../../../theme";
import { AddButton } from "../../../button";
import { ModalContext } from "../../../Modal";
import { StaffMemberModal } from "../../../modal/staff-member";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { OrgTableTabProps } from "../org-profile";

export const OrgStaff: React.FC<OrgTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchStaffByOrgId, fetchStaffByOrgIdWithSearch } = useRequests()

  const fetchStaffWithSearch = (search: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByOrgIdWithSearch(props.org.id, search, setItems, start, limit);
  }

  const fetchStaff = (setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByOrgId(props.org.id, setItems, start, limit);
  }

  const fullnameComponent = (column: ReactElement<RTableColumnProps>, s: StaffMember) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        fetchItemsWithSearch={fetchStaffWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/staff"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title='New Staff Member'
            testId='new-staff-member-button'
            onClick={() => setModalContent(
              <StaffMemberModal
                mode="add"
                org={props.org}
              />
            )}
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullnameComponent} />
          <RTableColumn id="email" name="Email Address" />
          <RTableColumn id="numClasses" name="#Classes" />
          <RTableColumn id="numStudents" name="#Students" />
        </RTableColumns>

      </AnalyticaTable>
    </>
  )
}
