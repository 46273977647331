import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext } from "../Account";
import { validateName } from "../../data/validations";
import React from "react";
import { AnalyticaForm } from "../form";
import { TextInput } from "../form/input";
import { Box } from "@chakra-ui/react";
import { Admin, StaffMember, User } from "../../data/classes";
import { RAProfile } from "./profile";
import { useRequests } from "../../data/requests";

export const UserProfile = () => {

  const { session, updateDetails, getCognitoId, isStaff, isAdmin } = useContext(AccountContext)
  const { fetchAdminByCognitoId, fetchStaffMemberByCognitoId, updateAdmin, updateStaffMember } = useRequests()

  const userIsStaff = isStaff()
  const userIsAdmin = isAdmin()
  const cognitoId = getCognitoId()
  const user = session?.getIdToken().payload

  const [userData, setUserData] = useState<User | null>(null)

  const load = useCallback(() => {
    let fetch = userIsStaff ? fetchStaffMemberByCognitoId : fetchAdminByCognitoId
    fetch(cognitoId, setUserData)
  }, [cognitoId, userIsStaff, fetchStaffMemberByCognitoId, fetchAdminByCognitoId])

  useEffect(() => {
    load()
  }, [load]);

  const onSuccess = () => {
  }

  const onError = (err: Error) => {
    console.error(err.message)
  }

  const updateUserProfile = (values: any, callback: Function) => {
    const updateInCognito = () => {
      // update in cognito
      updateDetails(values.preferredName, values.lastName, onSuccess, onError)
      callback()
    }
    // update in db
    if (userIsStaff) {
      updateStaffMember(values as StaffMember, updateInCognito)
    } else if (userIsAdmin) {
      updateAdmin(values as Admin, updateInCognito)
    }
  }

  const fullname = () => {
    if (!user) {
      return "-"
    }
    let fn = ''
    fn += user.given_name || '-'
    fn += ' '
    fn += user.family_name || '-'
    return fn
  }

  const email = () => {
    if (!user || !user.email) {
      return "-"
    }
    return user.email
  }

  let userFullName = fullname()
  let userEmail = email()

  const form = <AnalyticaForm
    name="update-profile"
    title="Update Profile"
    description='Update your User Profile.'
    initialValues={userData || {}}
    // onSubmit={item ? updateOrg : addOrg}
    onSubmit={updateUserProfile}
  >
    <AnalyticaForm.SingleItemForm>
      <TextInput
        name="preferredName"
        title="Preferred Name"
        testId="user-preferred-name-input"
        validation={validateName}
      />
      <Box h={5} />
      <TextInput
        name="lastName"
        title="Last Name"
        testId="user-last-name-input"
        validation={validateName}
      />
    </AnalyticaForm.SingleItemForm>
  </AnalyticaForm>

  return (
    <RAProfile
      primary={userFullName}
      secondary={userEmail}
      edit={form}
    />
  )
}
