import React, { ReactElement, ReactNode, useContext } from "react";
import { Avatar, Box, Heading, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../theme";
import { DeleteIconButton, EditIconButton } from "../button/icon";
import { ModalContext } from "../Modal";

export interface ProfileProps {
  children?: ReactElement | ReactElement[]
  primary: string
  secondary: string
  edit: ReactNode
  delete?: ReactNode
  additionalActions?: ReactElement[]
}

export const RAProfile: React.FC<ProfileProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)

  return (
    <Box
      paddingX={5}
      paddingBottom={5}
    >
      <Box
        padding={5}
        background={COLOUR_PRIMARY_WHITE}
        borderRadius={RADIUS_PRIMARY}
        border={BORDER_DEFAULT}
        w="full"
      >
        <HStack>
          <Avatar
            name={props.primary}
            src={""}
            size="lg"
          />
          <Box w={5} />
          <VStack align="flex-start">
            <Heading fontSize="2xl">{props.primary}</Heading>
            <Text>{props.secondary}</Text>
          </VStack>
          <Spacer />
          {props.additionalActions && props.additionalActions}

          {/* TODO: The on click functions should be  */}
          {/* opening and setting the content of the modal using setModalContent */}

          {props.edit && <EditIconButton testId="edit-icon" onClick={() => setModalContent(props.edit)} />}
          {props.delete && <DeleteIconButton testId="delete-icon" onClick={() => setModalContent(props.delete)} />}
        </HStack>
        <Box h={10} />
        <HStack>
          {props.children}
        </HStack>
      </Box>
    </Box>
  )
}
