import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Org, StaffMember } from "../../../data/classes";
import { fullname } from "../../../data/functions";
import { useRequests } from "../../../data/requests";
import { ICON_CLASS, ICON_STUDENT } from "../../../theme";
import { AccountContext } from "../../Account";
import { PageController } from "../../controller/page-controller";
import { Loading } from "../../loading";
import { StaffMemberModal } from "../../modal/staff-member";
import { StatTile } from "../../stats";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { StaffMemberClasses } from "./classes";
import { StaffMemberStudents } from "./students";

export interface StaffMemberTableTabProps extends AnalyticaTableTabProps {
  org: Org
  staffMember: StaffMember
}

export const StaffMemberProfile = () => {

  const { fetchOrgById, fetchStaffMemberById } = useRequests()
  const [staffMember, setStaffMember] = useState<StaffMember | null>()
  const [org, setOrg] = useState<Org | null>()

  let { orgId, staffMemberId } = useParams();

  const { setPageTitle } = useContext(AccountContext)

  const load = useCallback(() => {
    setPageTitle("Staff")
    if (orgId && staffMemberId) {
      fetchOrgById(orgId, setOrg)
      fetchStaffMemberById(staffMemberId, setStaffMember)
    }
  }, [setPageTitle, orgId, staffMemberId, fetchOrgById, fetchStaffMemberById])

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !staffMember) {
    return <Loading />
  }

  return (
    <PageController>
      <RAProfile
        primary={fullname(staffMember) || ''}
        secondary={org.name}
        edit={
          <StaffMemberModal
            mode="update"
            staffMember={staffMember}
            org={org}
          />
        }
        delete={
          <StaffMemberModal
            mode="delete"
            staffMember={staffMember}
            org={org}
          />
        }
      >
        <StatTile title="Total Active Classes" value={staffMember.numClasses} />
        <StatTile title="Total Active Students" value={staffMember.numStudents} />
      </RAProfile>

      <AnalyticaTableContainer>
        <StaffMemberClasses title="Classes" icon={ICON_CLASS} org={org} staffMember={staffMember} testId="view-classes" />
        <StaffMemberStudents title="Students" icon={ICON_STUDENT} org={org} staffMember={staffMember} testId="view-students" />
      </AnalyticaTableContainer>
    </PageController >
  )
}
