import { Modal, ModalOverlay, ModalCloseButton, Box } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { createContext } from "react";
import { AccountProps } from "./Account";

export type ModalMode = 'add' | 'update' | 'delete'
export const DEFAULT_MODAL_MODE: ModalMode = 'add'

export interface ModalContextProps {
  isOpen: boolean
  setModalContent: (content: ReactNode) => void
  onClose: () => void
}

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export interface ModalProviderProps {
  children: ReactNode | ReactNode[]
}

export const ModalProvider: React.FC<AccountProps> = ({ ...props }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);

  const setModalContent = (modalContent: ReactNode) => {
    setContent(modalContent)
    setIsOpen(true);
  }

  const onClose = () => {
    setContent(null)
    setIsOpen(false);
  }

  return (
    <ModalContext.Provider value={{ isOpen, setModalContent, onClose }}>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        size="lg"
        isCentered
        id="ra-modal"
      >
        <ModalOverlay bg="transparent" backdropFilter="blur(12px)" />
        <ModalCloseButton />
        {content}
      </Modal>
      {props.children}
    </ModalContext.Provider>
  )
}
