import { Box, Button, IconButton, Input, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { FieldArray, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { BiX } from 'react-icons/bi';
import { Class, Org, Student } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useRequests } from '../../../data/requests';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK } from '../../../theme';
import { CancelButton, SaveButton } from '../../button';
import { AnalyticaForm } from '../../form';
import { IntegerInput, TextInput } from '../../form/input';
import { ModalContext } from '../../Modal';
import { CSVUpload } from '../../upload/csv';

export interface ImportModalProps {
  org: Org
  clazz: Class
  callback: () => void
}

export const ImportModal: React.FC<ImportModalProps> = ({ ...props }) => {

  const [students, setStudents] = useState<Student[]>()
  const { onClose } = useContext(ModalContext)
  const toast = useToast()

  const { addStudent, downloadImportTemplateCSV } = useRequests()

  let title = "Import Students from CSV File"
  let description = `Add a new Student to ${props.clazz.name} at ${props.org.name}.`

  const save = async (values: { students: Student[] | undefined }, callback: () => void) => {
    if (values.students === undefined) {
      throw new Error("Students should not be undefined")
    }
    for (const s of values.students) {
      try {
        await addStudent(
          {
            ...s,
            level: 1,
            orgId: props.org.id,
            classes: [props.clazz.id],
          },
          () => { },
        )
      } catch (error) {
        if (error instanceof Error) {
          toast({
            title: "Error Importing Student",
            description:
              error.message === "Request failed with status code 409"
                ? `Student ${fullname(s)} could not be imported. They may already exist in another organisation.`
                : error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })
        }
      }
    }
    callback()
  }

  const download = () => {
    downloadImportTemplateCSV("template.csv")
  }

  let importStudentsFromCSV =
    <ModalContent borderRadius={10} border={BORDER_DEFAULT}>

      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <Text>{description}</Text>
        <Box pt={10} />
        <Button variant='link' textColor={COLOUR_PRIMARY_DARK} onClick={download} >Download CSV Template</Button>
        <Box h={5} />
        <CSVUpload setData={setStudents} />
        <Box pt={10} />
      </ModalBody>

    </ModalContent>

  // let oldForm = <Formik
  //   initialValues={{ students: students }}
  //   validateOnMount
  //   onSubmit={(values, actions) => {
  //     setTimeout(() => {
  //       save(values, () => {
  //         actions.setSubmitting(false)
  //         actions.resetForm()
  //         onClose()
  //         props.callback()
  //       })
  //     }, 1000)
  //   }}
  // >
  //   {({ values, isValid, isSubmitting }) => {
  //     return <Form>
  //       <ModalContent borderRadius={10} border={BORDER_DEFAULT}>
  //
  //         <ModalHeader>{title}</ModalHeader>
  //
  //         <ModalCloseButton />
  //
  //         <ModalBody>
  //           <Text>{description}</Text>
  //           <Box pt={10} />
  //
  //           <FieldArray name="students">
  //             {arrayHelpers => (
  //               <div>
  //                 <Table size="small" variant="unstyled">
  //                   <Thead>
  //                     <Tr>
  //                       <Th fontSize="xs">Identifier</Th>
  //                       <Th fontSize="xs">Preferred Name</Th>
  //                       <Th fontSize="xs">Last Name</Th>
  //                       <Th fontSize="xs">Year Level</Th>
  //                       <Th />
  //                     </Tr>
  //                   </Thead>
  //                   <Tbody>
  //                     {values.students && values.students.length > 0 ? (
  //                       values.students.map((item: Student, index) => (
  //                         <Tr key={index}>
  //                           <Td>
  //                             <Input
  //                               size="sm"
  //                               name={`students[${index}].identifier`}
  //                               value={values.students && values.students[index].identifier}
  //                               onChange={
  //                                 (e) => arrayHelpers.form.setFieldValue(`students[${index}].identifier`, e.target.value)
  //                               }
  //                             />
  //                           </Td>
  //                           <Td>
  //                             <Input
  //                               size="sm"
  //                               name={`students[${index}].preferredName`}
  //                               value={values.students && values.students[index].preferredName}
  //                               onChange={
  //                                 (e) => arrayHelpers.form.setFieldValue(`students[${index}].preferredName`, e.target.value)
  //                               }
  //                             />
  //                           </Td>
  //                           <Td>
  //                             <Input
  //                               size="sm"
  //                               name={`students[${index}].lastName`}
  //                               value={values.students && values.students[index].lastName}
  //                               onChange={
  //                                 (e) => arrayHelpers.form.setFieldValue(`students[${index}].lastName`, e.target.value)
  //                               }
  //                             />
  //                           </Td>
  //                           <Td>
  //                             <Input
  //                               type="number"
  //                               size="sm"
  //                               name={`students[${index}].yearLevel`}
  //                               value={values.students && values.students[index].yearLevel}
  //                               onChange={
  //                                 (e) => arrayHelpers.form.setFieldValue(`students[${index}].yearLevel`, parseInt(e.target.value))
  //                               }
  //                             />
  //                           </Td>
  //                           <Td>
  //                             <IconButton icon={<BiX />} size="sm" onClick={() => arrayHelpers.remove(index)} aria-label="" />
  //                           </Td>
  //                         </Tr>
  //                       ))
  //                     ) : (
  //                       <Tr>
  //                         <Td>No Items to show</Td>
  //                       </Tr>
  //                     )}
  //                   </Tbody>
  //                 </Table>
  //                 <Box h={5} />
  //                 <Button size="sm"
  //                   onClick={() => arrayHelpers.push({})}
  //                 >Add</Button>
  //               </div>
  //             )}
  //           </FieldArray>
  //           <Box pt={10} />
  //         </ModalBody>
  //
  //         <ModalFooter>
  //           <CancelButton w="25%" />
  //           <Spacer />
  //           <SaveButton testId="save-button" w="25%" isLoading={isSubmitting} isDisabled={!isValid} />
  //         </ModalFooter>
  //
  //       </ModalContent>
  //     </Form>
  //   }}
  // </Formik>

  const tableForm =
    <AnalyticaForm
      name='student-import-modal'
      title={title}
      description={description}
      initialValues={{ students: students }}
      mode={'add'}
      onSubmit={save}
    >
      <AnalyticaForm.TableForm<Student>
        fieldKey="students"
        isEditAddRemoveEnabled
      >
        <TextInput
          name="identifier"
          title="Identifier"
          testId='student-identifier-input'
          validation={validateNonEmpty}
        />
        <TextInput
          name="preferredName"
          title="Preferred Name"
          testId='student-preferred-name-input'
          validation={validateName}
        />
        <TextInput
          name="lastName"
          title="Last Name"
          testId='student-last-name-input'
          validation={validateName}
        />
        <IntegerInput
          name="yearLevel"
          title="Year Level"
          testId='class-year-level-input'
          validation={validateNonEmpty}
          min={1}
          max={12}
        />
      </AnalyticaForm.TableForm>
    </AnalyticaForm>

  return !students || students.length === 0 ? importStudentsFromCSV : tableForm
}
