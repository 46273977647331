import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Class, Org, Student } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { useRequests } from "../../../../data/requests";
import { ICON_EDIT, ICON_IMPORT, ICON_STAFF, ICON_STUDENT } from "../../../../theme";
import { AddButton } from "../../../button";
import { Loading } from "../../../loading";
import { ModalContext } from "../../../Modal";
import { ClassModal } from "../../../modal/class";
import { StudentModal } from "../../../modal/student";
import { ImportModal } from "../../../modal/student/import-modal";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { ClassTableTabProps } from "../class-profile";

export const ClassStudents: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)
  const { fetchOrgById, fetchClassById, fetchStudentsByClassId, fetchStudentsByClassIdWithSearch } = useRequests()
  const [org, setOrg] = useState<Org | null>()
  const [clazz, setClass] = useState<Class | null>()
  let { orgId, classId } = useParams();

  const load = useCallback(() => {
    if (orgId) {
      fetchOrgById(orgId, setOrg)
    }
    if (classId) {
      fetchClassById(classId, setClass)
    }
  }, [orgId, classId, fetchOrgById, fetchClassById])

  useEffect(() => {
    load()
  }, [load])

  if (!org || !clazz) {
    return <Loading />
  }

  const fetchStudentsWithSearch = (search: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByClassIdWithSearch(props.clazz.id, search, setItems, start, limit);
  }

  const fetchStudents = (setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByClassId(props.clazz.id, setItems, start, limit);
  }

  const fullnameComponent = (_column: ReactElement<RTableColumnProps>, s: Student) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStudents}
        fetchItemsWithSearch={fetchStudentsWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/students"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="New Student"
            testId="new-student-button"
            onClick={() => setModalContent(
              <StudentModal
                mode="add"
                org={props.org}
                clazz={props.clazz}
              />
            )}
          />
          <AddButton
            title="Import Students"
            testId="import-students-button"
            leftIcon={ICON_IMPORT}
            onClick={() => setModalContent(
              <ImportModal
                org={props.org}
                clazz={props.clazz}
                callback={() => load()}
              />
            )}
          />
          <AddButton
            title="Edit All Students"
            testId="edit-class-students-button"
            leftIcon={ICON_EDIT}
            onClick={() => setModalContent(
              <ClassModal mode='students' org={org} clazz={clazz} />
            )}
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullnameComponent} />
          <RTableColumn id="level" name="Level" />
          <RTableColumn id="yearLevel" name="yearLevel" />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>
      </AnalyticaTable>
    </>
  )
}
