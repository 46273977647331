import { useRequests } from '../../../data/requests';
import React from 'react'
import { AnalyticaForm } from '../../form';
import { validateEmail, validateName } from '../../../data/validations';
import { TextInput } from '../../form/input';
import { fullname } from '../../../data/functions';
import { Admin } from '../../../data/classes';
import { useNavigate } from 'react-router-dom';
import { ModalMode } from '../../Modal';

export interface AdminModalProps {
  admin?: Admin
  mode: ModalMode
}

export const AdminModal: React.FC<AdminModalProps> = ({ ...props }) => {

  const { inviteAdmin, updateAdmin, deleteAdmin } = useRequests()

  const navigate = useNavigate()
  const deleteAndReturn = (admin: Admin, callback: () => void) => {
    deleteAdmin(admin, callback)
    navigate('/admins')
  }

  let title
  let description
  let initialValues

  switch (props.mode) {
    case "add":
      title = "Invite an Admin"
      description = "Invite a new Admin to Rubric Analytica."
      initialValues = {}
      break
    case "update":
      if (!props.admin) {
        throw new Error("Item not supplied");
      }
      title = "Edit Admin Profile"
      description = "Update Admin Details."
      initialValues = props.admin
      break
    case "delete":
      if (!props.admin) {
        throw new Error("Item not supplied");
      }
      title = "Delete Admin"
      description = `Are you sure you want to delete Admin - ${fullname(props.admin)}?`
      initialValues = { id: props.admin.id }
      break
  }

  return (
    <AnalyticaForm
      name='admin-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode}
      onSubmit={
        props.mode === 'update' ? updateAdmin
          : props.mode === 'delete' ? deleteAndReturn
            : inviteAdmin}
    >
      {
        props.mode !== 'delete' &&
        <AnalyticaForm.SingleItemForm>
          <TextInput
            name="email"
            title="Email Address"
            testId='admin-email-input'
            validation={validateEmail}
          />
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='admin-preferred-name-input'
            validation={validateName}
            optional
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='admin-last-name-input'
            validation={validateName}
            optional
          />
        </AnalyticaForm.SingleItemForm>
      }
    </AnalyticaForm>
  )
}
