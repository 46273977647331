import {
  Box,
  Button,
  IconButton,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import React, { ChangeEvent, PropsWithChildren, useContext } from "react";
import { BORDER_DEFAULT, RADIUS_PRIMARY } from "../../theme";
import { ModalContext } from "../Modal";
import { CancelButton, SaveButton } from "../button";
import { DeleteButton } from "../button/DeleteButton";
import { RAInputProps } from "./input/RAInputProps";
import { BiX } from "react-icons/bi";

const R = require("ramda");

type FormMode = "add" | "update" | "delete";

interface AnalyticaFormProps<T extends Record<string, any>> extends PropsWithChildren {
  name: string;
  title: string;
  description: string;
  initialValues: T;
  mode?: FormMode;
  onSubmit: (...args: any[]) => void;
  callback?: () => void;
}

interface SingleItemFormProps extends PropsWithChildren {
}

interface TableFormProps extends PropsWithChildren {
  fieldKey: string;
  isEditAddRemoveEnabled?: boolean;
}

const SingleItemForm = ({
  children,
}: SingleItemFormProps): JSX.Element => (
  <>
    {children}
  </>
);

const TableForm = <T extends Record<string, any>,>({
  fieldKey,
  isEditAddRemoveEnabled = false,
  children,
}: TableFormProps): JSX.Element => {

  const { values } = useFormikContext<any>();
  const valuesField = values[fieldKey]

  const parseInput = (e: ChangeEvent<HTMLInputElement>, type: string | undefined) => {
    if (type === undefined) {
      return e.target.value
    }
    if (type === 'float') {
      return parseFloat(e.target.value)
    }
    if (type === 'integer') {
      return parseInt(e.target.value)
    }
    return e.target.value
  }

  return <FieldArray name="fieldArray">
    {(arrayHelpers) => (
      <div>
        <Table size="small" variant="unstyled">
          <Thead>
            <Tr>
              {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  const input = child.props as RAInputProps;
                  return <Th fontSize="xs">{input.title}</Th>
                }
                return <Td />;
              })}
              {isEditAddRemoveEnabled && <Th />}
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(valuesField) && valuesField.length > 0 ? (
              (valuesField as Array<T>).map((item: T, index) => (
                <Tr key={index} test-id={'form-row-' + index}>
                  {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                      const input = child.props as RAInputProps;
                      return <Td>
                        <Input
                          size='sm'
                          // TODO: make this type more dynamic. This could be done better.
                          type={input.type === 'integer' || input.type === 'float' ? 'number' : input.type}
                          name={`${String(fieldKey)}[${index}].${input.name}`}
                          value={item[input.name]}
                          test-id={input.testId}
                          onChange={(e) =>
                            arrayHelpers.form.setFieldValue(`${String(fieldKey)}[${index}].${input.name}`, parseInput(e, input.type))
                          }
                        />
                      </Td>
                    }
                    return <Td />;
                  })}
                  {isEditAddRemoveEnabled && (
                    <Td>
                      <IconButton
                        icon={<BiX />}
                        size="sm"
                        onClick={() => arrayHelpers.remove(index)}
                        aria-label=""
                      />
                    </Td>
                  )}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td>No Items to show</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Box h={5} />
        {isEditAddRemoveEnabled && (
          <Button size="sm" onClick={() => arrayHelpers.push({})}>
            Add
          </Button>
        )}
      </div>
    )}
  </FieldArray>
};

export const AnalyticaForm = Object.assign(
  <T extends Record<string, any>,>({
    title,
    description,
    initialValues,
    onSubmit,
    mode,
    callback,
    children
  }: AnalyticaFormProps<T>): JSX.Element => {

    const { onClose } = useContext(ModalContext);

    return (
      <Formik
        initialValues={initialValues || {}}
        validateOnMount
        enableReinitialize
        onSubmit={(values, actions) => {
          setTimeout(() => {
            onSubmit(values, () => {
              actions.setSubmitting(false);
              actions.resetForm();
              onClose();
              callback?.();
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalContent borderRadius={RADIUS_PRIMARY} border={BORDER_DEFAULT}>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box test-id="main-modal-content">
                  <Text>{description}</Text>
                  <Box pt={10} />
                  {children}
                  <Box pt={10} />
                </Box>
              </ModalBody>
              <ModalFooter>
                <CancelButton w="25%" />
                <Spacer />
                {mode === "delete" ? (
                  <DeleteButton
                    w="25%"
                    testId="delete-button"
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                  />
                ) : (
                  <SaveButton
                    w="25%"
                    testId="save-button"
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                  />
                )}
              </ModalFooter>
            </ModalContent>
          </Form>
        )}
      </Formik>
    );
  },
  { SingleItemForm, TableForm }
);

