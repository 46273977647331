import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountContext } from "../../Account";
import React from "react";
import { Org } from "../../../data/classes";
import { Loading } from "../../loading";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { OrgStaff } from "./staff";
import { OrgStudents } from "./students";
import { OrgModal } from "../../modal/org";
import { RAProfile } from "../profile";
import { StatTile } from "../../stats";
import { ICON_CLASS, ICON_STAFF, ICON_STUDENT } from "../../../theme";
import { PageController } from "../../controller/page-controller";
import OrgClasses from "./classes/org-classes";
import { useRequests } from "../../../data/requests";

export interface OrgTableTabProps extends AnalyticaTableTabProps {
  org: Org
}

export const OrgProfile = () => {

  const { setPageTitle } = useContext(AccountContext)
  const { fetchOrgById } = useRequests()
  const [org, setOrg] = useState<Org | null>()
  let { orgId } = useParams();

  const load = useCallback(() => {
    setPageTitle("Organisations")
    if (orgId) {
      fetchOrgById(orgId, setOrg)
    }
  }, [setPageTitle, orgId, fetchOrgById])

  useEffect(() => {
    load()
  }, [load]);

  if (!org) {
    return <Loading />
  }

  return (
    <PageController>
      <RAProfile
        primary={org.name}
        secondary={org.primaryAddress}
        edit={
          <OrgModal mode="update" org={org} />
        }
        delete={
          <OrgModal mode="delete" org={org} />
        }
      >
        <StatTile title="Total Active Classes" value={org.numClasses} />
        <StatTile title="Total Active Students" value={org.numStudents} />
        <StatTile title="Average Score Percentage" units="%" value={org.avgScore} format="percentage" />
      </RAProfile>

      <AnalyticaTableContainer>
        <OrgStaff title="Staff" icon={ICON_STAFF} org={org} testId="view-staff" />
        <OrgClasses title="Classes" icon={ICON_CLASS} org={org} testId="view-classes" />
        <OrgStudents title="Students" icon={ICON_STUDENT} org={org} testId="view-students" />
      </AnalyticaTableContainer>

    </PageController>
  )
}
